/* eslint-disable @typescript-eslint/no-unused-vars */
import { MySmallLogoIcon } from '@src/components/Svg';
import React, { useContext, useRef } from 'react';
import Regenerate from '@assets/repeat.svg';
import { v4 as uuidv4 } from 'uuid';
import Profile from '@assets/user-circle.svg';
import { useNavigate } from 'react-router';
import textLogo from '@assets/essay/GMind_Icon_Orange_compressed.png';
import darkTextLogo from '@assets/essay/GMind_Icon_White_compressed.png';
import Like from '@public/like.svg';
import DisLike from '@public/dislike.svg';
import Message from '@public/message-circle-2.svg';
import { getUrlPath } from '@src/utils/app_functions';
import { ThemeContext } from '@src/theme_provider/theme';

interface PromptOptionsType {
    name: string;
    icon: string;
    onClick: () => void;
}

interface TextProps {
    content: JSX.Element;
    options: PromptOptionsType[];
    onOpenEditor?: () => void;
    messageClick: () => void;
    likeClick: () => void;
    disLikeClick: () => void;
    regenerateClick?: () => void;
    profileClick: () => void;
    fromPage?: string;
    canShowOptions?: boolean;
}

const ResponseTextbox = ({
    fromPage = getUrlPath().pagename,
    content,
    options,
    regenerateClick,
    messageClick,
    likeClick,
    disLikeClick,
    canShowOptions = false,
}: TextProps) => {
    const UrlRef = useRef<string>('');
    const navigate = useNavigate();
    const { savedTheme } = useContext(ThemeContext);

    function handleHumanize() {
        console.log(content.props?.children);
        const assistant_content = content.props?.children;
        localStorage.setItem('essay_data', assistant_content ?? '');
        let queryParams = '';
        if (fromPage) {
            queryParams = `&fromPage=${fromPage}`;
        }
        const id = uuidv4();
        navigate(`/dashboard/article/editor?document=${id}${queryParams}`);
    }

    return (
        <div
            style={{ display: 'flex', flexDirection: 'column', marginTop: '24px' }}
            className="h-auto shadow-md dark:bg-[#2A334A] border border-[var(--gmind-border-black16)] rounded-[0.5rem]"
        >
            <div className="px-10 py-5 flex justify-between items-center">
                <img className="h-4" src={savedTheme === 'dark' ? darkTextLogo : textLogo} alt="logo" />

                <div className="flex gap-x-4 w-[60%] justify-end gap-3 items-center">
                    { canShowOptions && options.map((option, index) => (
                        <div
                            key={index}
                            onClick={option.onClick}
                            className="rounded-[1rem] px-0 md:px-[1rem] py-[0.3rem] bg-transparent md:bg-[var(--gmind-header)] flex gap-x-3 cursor-pointer"
                        >
                            <img className="w-[1rem] h-[1rem]" src={option.icon} alt="" />
                            <div className="hidden md:flex font-normal text-[0.75rem] text-[var(--gmind-plain-black)]">
                                {option.name}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div
                className="text-container dark:bg-[#2A334A] px-5 md:px-10"
                style={{
                    fontFamily: 'Inter',
                    fontWeight: 400,
                    fontSize: '13px',
                    color: '#333237',
                }}
            >
                {content}
            </div>

            <div className="px-10 my-5 flex items-center justify-between w-full">
                {/* <div className="flex gap-x-10 w-full md:w-[50%]">
                    <div
                        className="hidden md:flex items-center flex-row"
                        style={{ cursor: 'pointer' }}
                        onClick={regenerateClick}
                    >
                        <img src={Regenerate} alt="" />{' '}
                        <span
                            className="font-normal text-[0.75rem] text-[var(--gmind-plain-black)]"
                            style={{ marginLeft: '2px' }}
                        >
                            Regenerate
                        </span>
                    </div>
                    <div role="button" onClick={handleHumanize} className="flex items-center">
                        <img src={Profile} alt="" />
                        <p>Text Editor</p>
                    </div>
                </div> */}

                {/* <div className="flex gap-x-5 w-[30%] justify-end">
                    <img style={{ cursor: 'pointer' }} onClick={messageClick} src={Message} alt="" />
                    <img style={{ cursor: 'pointer' }} onClick={likeClick} src={Like} alt="" />
                    <img
                        style={{ cursor: 'pointer', marginRight: '20px' }}
                        onClick={disLikeClick}
                        src={DisLike}
                        alt=""
                    />
                </div> */}
            </div>
        </div>
    );
};

export default ResponseTextbox;
