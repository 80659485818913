import { FileContent } from "@src/core/interfaces/chat";

interface DataType<T>  {
    title: T;
    content: T;
    id: T;
    date?: T;
};


type Chats = { 
    role: string;
    reasoningContent?: string;
    content: ChatContent[] | string;
    type?: string 
    _id?: string;
};


export type ChatContent = {
    type:  'text' | 'image_url' | 'sources' | 'files' | 'video';
    text?: string;
    image_url?: ImageURL;
    sources?: ResponseSources[];
    files?: FileContent[];
    video?: VideoURL[];
}

export type VideoURL = {
    url: string;
}

export interface ResponseSources {
    title: string;
    image?: string;
    provider?: string;
    providerImage?: string;
    reporter?: string;
}

export type ImageURL = {
    url: string;
}

export type {
    DataType,
    Chats
}

export interface PictureUploadResponse {
    success: boolean;
    message: string;
    url?: string;
    base64?: string;
}

export interface ExtractDocumentResponse {
    success: boolean;
    message: string;
    content?: string;
}

export interface ResponseSources {
    title: string;
    url: string;
    image?: string;
    provider?: string;
    providerImage?: string;
    reporter?: string;
}



export const isChatContentArray = (variable: any): variable is ChatContent[] => {
    return Array.isArray(variable) && variable.every(item => 
        typeof item === 'object' &&
        item !== null &&
        typeof item.type === 'string' &&
        (item.text === undefined || typeof item.text === 'string') &&
        (item.image_url === undefined ||
         (typeof item.image_url === 'object' &&
          item.image_url !== null &&
          typeof item.image_url.url === 'string'))
    );
}


export const getAllChatContentImages = (value: any) : string[] =>  {
    const isChatContent = isChatContentArray(value);

    if ( !isChatContent ) return [];

    const variable : ChatContent[] = value;

    let images: string[] = [];

    variable.map((element, index) => {
        const imageValue = element.image_url?.url;
        if ( imageValue ){
            images.push(imageValue);
        }
    });

    return images;
}


export const getIndicesOfSourcesInChatContent = (data: any): number[]  => {
    let indexes: number[] = [];
    if (isChatContentArray(data)) {
        for (let index = 0; index < data.length; index++) {
            if ( ( data[index].sources ?? [] ).length > 0 && data[index].type === 'sources' ) {
                indexes.push( index );
            }
        }
    }
    return indexes;
}

export const getIndicesOfVideosInChatContent = (data: any): number[]  => {
    let indexes: number[] = [];
    if (isChatContentArray(data)) {
        for (let index = 0; index < data.length; index++) {
            if ( ( data[index].video ?? [] ).length > 0 && data[index].type === 'video' ) {
                indexes.push( index );
            }
        }
    }
    return indexes;
}

export const getIndicesOfImagesInChatContent = (data: any): number[]  => {
    let indexes: number[] = [];
    if (isChatContentArray(data)) {
        for (let index = 0; index < data.length; index++) {
            if ( data[index].image_url !== undefined && data[index].type === 'image_url' ) {
                indexes.push( index );
            }
        }
    }
    return indexes;
}