import React, { Suspense, useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Header from '../components/header';
import LandingPage from '../root';
import './Layout.scss';
import { ToastContainer } from 'react-toastify';
import { getUrlPath } from '@src/utils/app_functions';
// import DashboardFrame from '@src/components/DashboardFrame';
const DashboardFrame = React.lazy(() => import('@src/components/DashboardFrame.tsx'));
import FeaturesScreen from '../features';
import { acquisitionRoutePages, AcquisitionRouteValues, chatRoutes } from '@src/utils/app_routes';
import Loading from '@src/components/Loading';

interface LayoutProps {
    children?: React.ReactNode;
    isLoggedIn?: boolean;
}

const Layout = ({ children, isLoggedIn = false }: LayoutProps) => {
    const homePage = AcquisitionRouteValues.home.page;
    const productivityPage = AcquisitionRouteValues.productivity.route;
    const creationPage = AcquisitionRouteValues.creation.page;
    const companyPage = AcquisitionRouteValues.company.page;
    const companyRoute = AcquisitionRouteValues.company.route;
    const assistantPage = AcquisitionRouteValues.assistant.page;
    const pricingPage = AcquisitionRouteValues.pricing.page;
    const pricingRoute = AcquisitionRouteValues.pricing.route;

    const [bgClass, setBgClass] = useState('');
    const location = useLocation();
    const [isRoot, setIsRoot] = useState<boolean>(getUrlPath().pagename === homePage);
    const [isFeatures, setIsFeatures] = useState<boolean>(location.pathname === productivityPage);
    const faqRef = useRef<HTMLDivElement | null>(null);
    const featuresRef = useRef<HTMLDivElement | null>(null);
    const [isMobile, setIsMobile] = useState(true);
    const [showFrame, setShowFrame] = useState<boolean>(false);
    const [editorView, setEditorView] = useState<boolean>(false);
    const path = getUrlPath().pagename
    const [isHome, setIsHome] = useState(false);
    const location_page = useLocation();
    // useEffect(() => {
    //     if (window.innerWidth >= 768) {
    //         setIsMobile(false);
    //     }
    // }, [window.innerWidth]);

    useEffect(() => {
        const updateBgClass = () => {
            const currentPage = getUrlPath().pagename;
            const isMobile = window.innerWidth < 1024;

            let computedBgClass = '';

            if ([creationPage, companyPage, assistantPage].includes(currentPage)) {
                computedBgClass = 'bg-[#000A26]';
            } else if ([homePage, acquisitionRoutePages.pricing].includes(currentPage)) {
                computedBgClass = 'md:bg-[#F1F1EA] bg-transparent';
            } else {
                computedBgClass = 'bg-[#031A57]';
            }

            // Override for gradient on small screens
            if (isMobile && currentPage === homePage) {
                computedBgClass = 'bg-gradient-to-b from-[#001F73] to-[#00040D]';
            }

            setBgClass(computedBgClass);
        };

        updateBgClass(); // Set initial value
        window.addEventListener('resize', updateBgClass); // Update on resize

        return () => window.removeEventListener('resize', updateBgClass); // Cleanup on unmount
    }, [location_page]);

    useEffect(() => {
        let page = getUrlPath().pagename;
        if (page == homePage ) {
            setIsHome(true);
        } else {
            setIsHome(false);
        }
    }, [window.location.pathname])

    useEffect(() => {
        let urlDetails = getUrlPath();
        if (urlDetails.pagename.toLowerCase() === pricingPage) {
            setShowFrame(true);
        } else if (urlDetails.pagename.toLowerCase().includes( chatRoutes.articleEditor ) ) {
            setEditorView(true);
        } else {
            setShowFrame(false);
        }

        setIsRoot(getUrlPath().pagename === homePage);
        setIsFeatures(location.pathname === productivityPage);
    }, [window.location.pathname]);

    useEffect(() => {
        if (window.innerWidth >= 768) {
            setIsMobile(false);
        }
    }, [window.innerWidth]);
    const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };
    const navigate = useNavigate();

    console.log("which page ", `${isRoot}`);

    return (
        <div className={`${!editorView ? 'layout-container  flex justify-center items-start' : 'w-screen'} min-h-screen ${ isHome ? 'bg-white' : 'bg-white' } `}>
            {showFrame ? (
                // <DashboardFrame />
                <Suspense
                    fallback={
                        <div>
                            <Loading />
                        </div>
                    }
                >
                    <DashboardFrame />
                </Suspense>
            ) : (
                <>
                    {!editorView ? (
                        <div className={`layout  min-w-custom w-full h-full ${bgClass}`}>
                            <header>
                                {/* <div className={`w-full px-10 md:px-20`}>
                                    <Header
                                        onClickFaQ={() => scrollToSection(faqRef)}
                                        onClickCompany={() => navigate('/company')}
                                        onClickFeature={() =>
                                            isFeatures ? scrollToSection(featuresRef) : navigate('/features')
                                        }
                                        onClickPricing={() =>
                                            isLoggedIn ? navigate('/dashboard/pricing') : navigate('/pricing')
                                        }
                                        isLoggedIn={isLoggedIn ?? false}
                                        isPromo={false}
                                        showHeader={true}
                                    />
                                </div> */}

                                <div className={`w-full`}>
                                    <Header
                                        onClickFaQ={() => scrollToSection(faqRef)}
                                        onClickCompany={() => navigate(companyRoute)}
                                        onClickFeature={() =>
                                            isFeatures ? scrollToSection(featuresRef) : navigate(productivityPage)
                                        }
                                        onClickPricing={() =>
                                            isLoggedIn ? navigate(chatRoutes.pricing) : navigate(pricingRoute)
                                        }
                                        isLoggedIn={isLoggedIn ?? false}
                                        isPromo={false}
                                    />
                                </div>
                            </header>
                            <main className='w-full'>
                                {isRoot ? (
                                    <LandingPage isLoggedIn={isLoggedIn} faqRef={faqRef} />
                                ) : isFeatures ? (
                                    <FeaturesScreen isLoggedIn={isLoggedIn} faqRef={faqRef} featuresRef={featuresRef} />
                                ) : (
                                    <Outlet />
                                )}
                            </main>
                        </div>
                    ) : (
                        <Outlet />
                    )}
                </>
            )}
            <ToastContainer />
        </div>
    );
};

export default Layout;
