import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import React, { Suspense } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import StatusComponent from './pages/acquisition/components/status';
// import NotFoundPage from './pages/404';
const NotFoundPage = React.lazy(() => import('./pages/404'));

import LoginPage from './pages/acquisition/login';
import SignUpPage from './pages/acquisition/signup';
import Layout from './pages/acquisition/layout/Layout';
import Cookies from './pages/acquisition/policies/cookies';
import TermsOfService from './pages/acquisition/policies/tos';
import Privacy from './pages/acquisition/policies/privacy';
import PricingHome from './pages/acquisition/pricing/home/page';
import EmailVerification from './pages/acquisition/verify_mail';
import OtpScreen from './pages/acquisition/verify_mail/otp';
import MaintenancePage from './pages/acquisition/maintanance/maintainance';
import ForgotPage from './pages/acquisition/forgot_password';
import Company from './pages/acquisition/company/Company';
import Features2 from './pages/acquisition/features/features2';
import Features3 from './pages/acquisition/features/features3';
import Features4 from './pages/acquisition/features/features4';
import { acquisitionRoutePages } from './utils/app_routes';
import Loading from './components/Loading/index.tsx';



export const AcquisitionRoutes = (): React.ReactElement =>  <Route path="index" element={<Layout />}>
    <Route path={acquisitionRoutePages.home} element={<Layout />} errorElement={<Suspense
                        fallback={
                            <div>
                                <Loading />
                            </div>
                        }
                    >
                        <NotFoundPage />
                    </Suspense>} />
    <Route path={acquisitionRoutePages.login} element={<LoginPage />} errorElement={<Suspense
                        fallback={
                            <div>
                                <Loading />
                            </div>
                        }
                    >
                        <NotFoundPage />
                    </Suspense>} />
    <Route path={acquisitionRoutePages.company} element={<Company isLoggedIn={ localStorage.getItem('token') ? true: false } />} errorElement={<Suspense
                        fallback={
                            <div>
                                <Loading />
                            </div>
                        }
                    >
                        <NotFoundPage />
                    </Suspense>} />
    <Route path={acquisitionRoutePages.forgotPassword} element={<ForgotPage />} errorElement={<Suspense
                        fallback={
                            <div>
                                <Loading />
                            </div>
                        }
                    >
                        <NotFoundPage />
                    </Suspense>} />
    <Route path={acquisitionRoutePages.getStarted} element={<SignUpPage />} errorElement={<Suspense
                        fallback={
                            <div>
                                <Loading />
                            </div>
                        }
                    >
                        <NotFoundPage />
                    </Suspense>} />
    <Route path={acquisitionRoutePages.cookies} element={<Cookies />} errorElement={<Suspense
                        fallback={
                            <div>
                                <Loading />
                            </div>
                        }
                    >
                        <NotFoundPage />
                    </Suspense>} />
    <Route path={acquisitionRoutePages.termsOfService} element={<TermsOfService />} errorElement={<Suspense
                        fallback={
                            <div>
                                <Loading />
                            </div>
                        }
                    >
                        <NotFoundPage />
                    </Suspense>} />
    <Route path={acquisitionRoutePages.privacyPolicy} element={<Privacy />} errorElement={<Suspense
                        fallback={
                            <div>
                                <Loading />
                            </div>
                        }
                    >
                        <NotFoundPage />
                    </Suspense>} />
    {/* <Route path="playground" element={<PlaygroundResponse />} errorElement={<Suspense
                        fallback={
                            <div>
                                <Loading />
                            </div>
                        }
                    >
                        <NotFoundPage />
                    </Suspense>} /> */}
    {/* <Route path={acquisitionRoutePages.pricing} element={<PricingHome />} errorElement={<Suspense
                        fallback={
                            <div>
                                <Loading />
                            </div>
                        }
                    >
                        <NotFoundPage />
                    </Suspense>} /> */}
    <Route path={acquisitionRoutePages.success} element={<PricingHome />} errorElement={<Suspense
                        fallback={
                            <div>
                                <Loading />
                            </div>
                        }
                    >
                        <NotFoundPage />
                    </Suspense>} />
    <Route path={acquisitionRoutePages.cancel} element={<PricingHome />} errorElement={<Suspense
                        fallback={
                            <div>
                                <Loading />
                            </div>
                        }
                    >
                        <NotFoundPage />
                    </Suspense>} />
    <Route path={acquisitionRoutePages.verifyMail} element={<EmailVerification />} errorElement={<Suspense
                        fallback={
                            <div>
                                <Loading />
                            </div>
                        }
                    >
                        <NotFoundPage />
                    </Suspense>} />
    <Route path={acquisitionRoutePages.verify} element={<OtpScreen />} errorElement={<Suspense
                        fallback={
                            <div>
                                <Loading />
                            </div>
                        }
                    >
                        <NotFoundPage />
                    </Suspense>} />
    <Route path={acquisitionRoutePages.productivity} element={<Layout />} errorElement={<Suspense
                        fallback={
                            <div>
                                <Loading />
                            </div>
                        }
                    >
                        <NotFoundPage />
                    </Suspense>} />
    <Route path={acquisitionRoutePages.creation} element={<Features2  />} errorElement={<Suspense
                        fallback={
                            <div>
                                <Loading />
                            </div>
                        }
                    >
                        <NotFoundPage />
                    </Suspense>} />
    <Route path={acquisitionRoutePages.assistant} element={<Features3 />} errorElement={<Suspense
                        fallback={
                            <div>
                                <Loading />
                            </div>
                        }
                    >
                        <NotFoundPage />
                    </Suspense>} />
    <Route path={acquisitionRoutePages.researchers} element={<Features4 />} errorElement={<Suspense
                        fallback={
                            <div>
                                <Loading />
                            </div>
                        }
                    >
                        <NotFoundPage />
                    </Suspense>} />
    {/* <Route path="dashboard/article/editor" element={<EditorPage />} errorElement={<NotFoundPage />} /> */}
    <Route path={acquisitionRoutePages.status} element={<StatusComponent />} />
</Route>;
