import React, { useEffect, useRef, useState } from 'react';
import { Dropdown, Menu, MenuProps } from 'antd';
import interact from 'interactjs';
import darkLogo from '@assets/essay/gmind_circular.png';
import { MdHighQuality } from 'react-icons/md';
import { SpeakerIcon, StarsIcon } from 'lucide-react';
import { LeftOutlined } from '@ant-design/icons';
import Pen from '@assets/essay/magicpen.png';

const TweakerButton = ({
    handleImproveQuality,
    handleChangeTone,
    handleRewrite,
    isTyping,
    handleLength,
}: {
    handleImproveQuality?: (condition: string) => void;
    handleChangeTone?: (condition: string) => void;
    handleRewrite?: () => void;
    handleLength?: (condition: string) => void;
    isTyping: {
        [key: string]: boolean;
    };
}) => {
    const buttonRef = useRef<HTMLButtonElement>(null);

    // Menus for the dropdowns
    const items: MenuProps['items'] = [
        {
            key: 'tone',
            label: (
                <Dropdown
                    menu={{
                        items: [
                            {
                                key: 'Formal',
                                label: 'Formal',
                                onClick: (e) => {
                                    e.domEvent.stopPropagation();
                                    handleChangeTone?.('Formal');
                                },
                            },
                            {
                                key: 'Informative',
                                label: 'Informative',
                                onClick: (e) => {
                                    e.domEvent.stopPropagation();
                                    handleChangeTone?.('Informative');
                                },
                            },
                            {
                                key: 'Persuasive',
                                label: 'Persuasive',
                                onClick: (e) => {
                                    e.domEvent.stopPropagation();
                                    handleChangeTone?.('Persuasive');
                                },
                            },
                            {
                                key: 'Conversational',
                                label: 'Conversational',
                                onClick: (e) => {
                                    e.domEvent.stopPropagation();
                                    handleChangeTone?.('Conversational');
                                },
                            },
                            {
                                key: 'Friendly',
                                label: 'Friendly',
                                onClick: (e) => {
                                    e.domEvent.stopPropagation();
                                    handleChangeTone?.('Friendly');
                                },
                            },
                        ],
                    }}
                    trigger={['hover']}
                    placement="topLeft"
                >
                    <div className="flex items-center gap-2">
                        {isTyping['tone'] ? <EllipsisLoader /> : <LeftOutlined className="h-4 w-4" />}
                        <span>Change Tone</span>
                    </div>
                </Dropdown>
            ),
        },
        {
            key: 'length',
            label: (
                <Dropdown
                    menu={{
                        items: [
                            {
                                key: 'Increase',
                                label: 'Increase',
                                onClick: (e) => {
                                    e.domEvent.stopPropagation();
                                    handleLength?.('increase');
                                },
                            },
                            {
                                key: 'Decrease',
                                label: 'Decrease',
                                onClick: (e) => {
                                    e.domEvent.stopPropagation();
                                    handleLength?.('decrease');
                                },
                            },
                        ],
                    }}
                    trigger={['hover']}
                    placement="topLeft"
                >
                    <div className="flex items-center gap-2">
                        {isTyping['length'] ? <EllipsisLoader /> : <LeftOutlined className="h-4 w-4" />}
                        <span>Change Length</span>
                    </div>
                </Dropdown>
            ),
        },
        {
            key: 'quality',
            label: (
                <Dropdown
                    menu={{
                        items: [
                            {
                                key: 'Comprehensive',
                                label: 'Comprehensive',
                                onClick: (e) => {
                                    e.domEvent.stopPropagation();
                                    handleImproveQuality?.('Comprehensive');
                                },
                            },
                            {
                                key: 'Engaging',
                                label: 'Engaging',
                                onClick: (e) => {
                                    e.domEvent.stopPropagation();
                                    handleImproveQuality?.('Engaging');
                                },
                            },
                        ],
                    }}
                    trigger={['hover']}
                    placement="topLeft"

                    // Adjust the offset to position the dropdown below the button
                >
                    <div className="flex items-center gap-2">
                        {isTyping['quality'] ? <EllipsisLoader /> : <LeftOutlined className="h-4 w-4" />}
                        <span>Improve Quality</span>
                    </div>
                </Dropdown>
            ),
        },
        {
            key: 'rewrite',
            label: (
                <div
                    className="flex items-center gap-2"
                    onClick={(e) => {
                        e.stopPropagation();

                        handleRewrite && handleRewrite?.();
                    }}
                >
                    {isTyping['rewrite'] ? <EllipsisLoader /> : <LeftOutlined className="h-4 w-4" />}
                    <span>Rewrite</span>
                </div>
            ),
        },
    ];

    return (
        <Dropdown menu={{ items }} trigger={['click']}>
            <button className=" border rounded-md border-customOrange py-1 w-[105px] h-[34px] px-3 flex items-center justify-center gap-x-2">
                <span className="text-customOrange">Tweaker</span>
                <span>
                    <img src={Pen} alt="pen" />
                </span>
            </button>
        </Dropdown>
    );
};

export default TweakerButton;
const EllipsisLoader = () => (
    <div className="flex space-x-1">
        <div className="w-4 h-4 border-blue-500 rounded-full  animate-spin border-[2px] transition dashed "></div>
    </div>
);
