/* eslint-disable @typescript-eslint/no-unused-vars */
import { toast } from "react-toastify";
// import 'react-quill/dist/quill.snow.css';
import  Quill  from "quill";
import axios from "axios";
import { ExtractedData, shareInterface } from "@src/core/interfaces/general";
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router';
import html2md from 'html-to-md'
import { parseDocument } from 'htmlparser2';
import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"
import location from '@icons/Location.svg';
import { ChatType } from "@src/core/interfaces/chat";
import { Socket } from "socket.io-client";
import { UserDetailsData } from "@src/core/interfaces/user";
import FingerprintJS from '@fingerprintjs/fingerprintjs';


// const navigate = useNavigate();

interface urlPathData {
    folder: string,
    pagename: string,
}

interface stringReplaceOutput {
    replacedText: string;
    extractedDivs: string[];
}

const getUrlPath = (): urlPathData => {
    const url = window.location.pathname;
    const urlPath = url.split("/");
    const length = urlPath.length;
    const webPage = urlPath[length - 1];
    const folder = urlPath[length - 2];

    return {
        folder: folder,
        pagename: webPage
    }
}

const copyData = (data: string) => {
    if(navigator.clipboard){
        try {
            navigator.clipboard.writeText(data);
            toast.success(`Copied successfully!`);
        }catch(error){
            console.error("Sorry, cannot copy")
        } 
    }
}

const shareResponse = (data: string) => {
    if ( navigator.share ) {
        try {
            navigator.share({
                url: data,
                title: 'Gmind AI'
            }); 
        } catch (error) {
            console.log(error);
            toast.error("Sorry, you cannot share the response for some reason, kindly check your settings");
        }
    } else {
        toast.error("Sorry, you cannot share the response for some reason, kindly check your settings");
    }
}

const convertStringToDelta = (text: string) : any => {
    const data = {text}
    const quill = new Quill(document.createElement('div')); // Create a Quill instance
    const delta = quill.clipboard.convert(data);

    return delta;
}

const handleRoute = (path: string) => {
    window.location.href = path
}

const clickDomElement = (id: string) => {
    const element = document.getElementById(id);
    if ( element ) element.click();
}


const handleShare = async ({ response, onSuccess, urlRef }: shareInterface) => {
    // const data = Document.current?.data?.ops;
    // const to_markdown = deltaToMarkdown(data);
    
    if (urlRef.current) {
        onSuccess(true);
        return;
    }
    const responseData = await axios.post(
        'https://api-v2.gmind.ai/v1/document/markdown-to-doc',
        { content: response },
        {},
    );

    const { data: d } = responseData;
    if (d?.statusCode === 1) {
        const url = `https://api-v2.gmind.ai/${d?.url}`;
        urlRef.current = url;
        onSuccess(true);
    }
}

const capitalizeFirstLetter = (value: string) => {
    if (!value) return '';
    return value.charAt(0).toUpperCase() + value.slice(1);
}

const addQueryParameter = (key: string, value: string) => {
    const url = new URL(window.location.href);
    url.searchParams.set(key, value); // Add or update the query parameter
    window.history.pushState({}, '', url.toString()); // Update the URL without navigation
};
const openCurriculumEditor = ( {
    response, pageId, promptSent, promptType, navigate, fromPage = undefined, pageSocket, 
}: { 
    response:string; 
    pageId?: string; 
    promptSent: string; 
    promptType: string; 
    navigate: (e: string) => void,
    fromPage?: string | undefined,
    pageSocket?: Socket | null;
} ) => {
    const id = pageId ?? uuidv4();

    const assistant_content = response;
    localStorage.setItem('essay_data', assistant_content);
    if ( promptSent != '' ){
        localStorage.setItem('prompt_data', JSON.stringify({
            fromPage: fromPage ?? getUrlPath().pagename,
            doc_id: id,
            promptSent,
            promptType
        }));
    }
    
    let queryParams = '';
    
    queryParams = `&fromPage=${ fromPage ?? getUrlPath().pagename}`;


    const userData = localStorage.getItem('user');

    if ( !userData || userData == '' ) return;

    const user: UserDetailsData = JSON.parse(userData);

    // save document with uuid to the database via socket
    let socketData = {
        promptSent: promptSent,
        promptType: promptType,
        fromPage: fromPage ?? getUrlPath().pagename,
        content: assistant_content,
        doc_id: id,
        user_id: user.userId
    }

    if ( pageSocket ){
        pageSocket?.emit('store-document', socketData);   

    }
  addQueryParameter('document', id);

    // navigate(`/dashboard/article/editor?document=${id}${queryParams}`);
}

const openContentEditor = ( {
    response, pageId, promptSent, promptType, navigate, fromPage = undefined, pageSocket, 
}: { 
    response:string; 
    pageId?: string; 
    promptSent: string; 
    promptType: string; 
    navigate: (e: string) => void,
    fromPage?: string | undefined,
    pageSocket?: Socket | null;
} ) => {
    const id = pageId ?? uuidv4();

    const assistant_content = response;
    localStorage.setItem('essay_data', assistant_content);
    if ( promptSent != '' ){
        localStorage.setItem('prompt_data', JSON.stringify({
            fromPage: fromPage ?? getUrlPath().pagename,
            doc_id: id,
            promptSent,
            promptType
        }));
    }
    
    let queryParams = '';
    
    queryParams = `&fromPage=${ fromPage ?? getUrlPath().pagename}`;


    const userData = localStorage.getItem('user');

    if ( !userData || userData == '' ) return;

    const user: UserDetailsData = JSON.parse(userData);

    // save document with uuid to the database via socket
    let socketData = {
        promptSent: promptSent,
        promptType: promptType,
        fromPage: fromPage ?? getUrlPath().pagename,
        content: assistant_content,
        doc_id: id,
        user_id: user.userId
    }

    if ( pageSocket ){
        pageSocket?.emit('store-document', socketData);   

    }
  addQueryParameter('document', id);

    // navigate(`/dashboard/article/editor?document=${id}${queryParams}`);
}


const openEditor = ( {
    response, pageId, promptSent, promptType, navigate, fromPage = undefined, pageSocket, 
}: { 
    response:string; 
    pageId?: string; 
    promptSent: string; 
    promptType: string; 
    navigate: (e: string) => void,
    fromPage?: string | undefined,
    pageSocket?: Socket | null;
} ) => {
    const id = pageId ?? uuidv4();

    const assistant_content = response;
    localStorage.setItem('essay_data', assistant_content);
    if ( promptSent != '' ){
        localStorage.setItem('prompt_data', JSON.stringify({
            fromPage: fromPage ?? getUrlPath().pagename,
            doc_id: id,
            promptSent,
            promptType
        }));
    }
    
    let queryParams = '';
    
    queryParams = `&fromPage=${ fromPage ?? getUrlPath().pagename}`;


    const userData = localStorage.getItem('user');

    if ( !userData || userData == '' ) return;

    const user: UserDetailsData = JSON.parse(userData);

    // save document with uuid to the database via socket
    let socketData = {
        promptSent: promptSent,
        promptType: promptType,
        fromPage: fromPage ?? getUrlPath().pagename,
        content: assistant_content,
        doc_id: id,
    }

    if ( pageSocket ){
        pageSocket?.emit('store-document', socketData);   

    }
 

    navigate(`/dashboard/article/editor?document=${id}${queryParams}`);
}

const getDaySuffix = (day: number): string => {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
    }
}

// Function to format the date
const formatDate = (date: Date): string => {
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1; // Months are zero-indexed
    const year = date.getUTCFullYear();
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    
    // Convert hours to 12-hour format
    const hours12 = hours % 12 || 12;
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Pad minutes with leading zero if needed
    const minutesStr = minutes < 10 ? `0${minutes}` : `${minutes}`;
    
    // Construct the formatted date string
    // return `${day}${getDaySuffix(day)} ${month} ${year} ${hours12}:${minutesStr}${ampm}`;
    return `${month}/${day}/${year} ${hours12}:${minutesStr}${ampm}`;
}

const dateFormat = ({dateSent}: {dateSent: string}) : string => {
    const dateValue: Date = new Date(dateSent);

    return formatDate(dateValue);
}

const imageReplaceTextValue = 'X9f4K7d8B2z1Q5w3M0T6uJ8a4R9n2L7p3E0xW';

const replaceImageElement = (input: string) : stringReplaceOutput => {
    const divRegex = /<div class="([^"]*\bse-component\b[^"]*\bse-image-container\b[^"]*)">.*?<\/div>/gs;
    const extractedDivs: string[] = [];
    let matchIndex = 0;
    const replacedText = input.replace(divRegex, (match) => {
        extractedDivs.push(match);
        return `${imageReplaceTextValue}${matchIndex++}`;
    });

    return { replacedText, extractedDivs };
}

const handleTextReplace = (content: string, textsToReplace: string[] ): string => {
        
    if ( textsToReplace.length == 0 ) return content;

    let newContent: string = content;
    for (let index = 0; index < textsToReplace.length; index++) {
        const element = textsToReplace[index];

        const replaceCheck = `${imageReplaceTextValue}${index}`;

        console.log(replaceCheck);
        console.log(newContent);
        console.log(element);


        newContent = newContent.replace(replaceCheck, element);
    }

    return newContent;
}

const convertToMarkdownFormat = (markdown: string): string => {
    let convertedMarkdown = markdown
    .replace(/\\#/g, '#')
    .replace(/\\\*/g, '*')
    .replace(/…/g, '...');


    return convertedMarkdown;
}

const convertHtmlToMarkdown = (htmlString: string) => {
    const document = parseDocument(htmlString);
    console.log(document);
    // const md = customHtmlToMd(document);

    // return md;
};

const customHtmlToMd = (document: any) => {
    // Traverse the HTML document and convert to Markdown, preserving styles
    let md = '';
    const traverse = (node: any) => {
      if (node.type === 'text') {
        md += node.data;
      } else if (node.type === 'tag') {
        if (node.name === 'span' && node.attribs.style) {
          const style = node.attribs.style;
          if (style.includes('color')) {
            const color = style.match(/color:\s*([^;]+)/i)?.[1];
            md += `<span style="color:${color}">`;
          }
        } else {
          const tag = node.name;
          md += `<${tag}>`;
        }

        if (node.children) {
          node.children.forEach((child: any) => traverse(child));
        }

        if (node.name === 'span' && node.attribs.style && node.attribs.style.includes('color')) {
          md += `</span>`;
        } else {
          const tag = node.name;
          md += `</${tag}>`;
        }
      }
    };

    traverse(document);
    return html2md(md);
};


const isHtmlString = (inputString: string): boolean => {
    const htmlPattern = /<[^>]+>/;
    return htmlPattern.test(inputString);
};

const getUserLocation = async () => {
    try {
      const response = await axios.get('https://get.geojs.io/v1/ip/geo.json');
      return response.data;
    } catch (error) {
      console.error('Error fetching the user location:', error);
      return null;
    }
};

const getRegion = async (): Promise< { location: 'ngn' | 'usd' } > => {
    let location = await getUserLocation();

    if (!location) return { location: 'ngn' };
  
    const { country, continent_code } = location;
  
    if (country.toString().toLowerCase() === 'nigeria') {
        return { location: 'ngn' };
    }

    return { location: 'usd' };
  
    // switch (continent_code) {
    //   case 'EU':
    //     return 'Europe';
    //   case 'NA':
    //   case 'SA':
    //     return 'America';
    //   default:
    //     return 'Other';
    // }
};


const extractAssistantData = (messages: ChatType[]): ExtractedData | null => {
    let date = '';
    let time = '';
    let content = '';
    let id = '';
    let title = '';
    let documentId = '';
  
    for (const message of messages) {
      if (message.role === 'user' && ( message.content !== '' || ( message.new_content ?? [] ).length > 0 ) ) {
        content = message.content !== '' ? message.content : ( 
            ( ( message.new_content ?? [] ).length > 0 ) ? message.new_content![0].text ?? '' : ''
        );
      }
      if (message.date) {
        date = message.date;
      }
      if (message.id) {
        id = message.id;
      }

      if (message.title) {
        title = message.title;
      }

      if (message.documentId) {
        documentId = message.documentId;
      }
    }
  
    if (id && content) {
      return { date, time, content, id, title, documentId };
    }
  
    return null;
  }


export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs))
}

const getDeviceId = async () => {
    return await getUniqueDeviceId();
    // // Check if deviceId exists in localStorage
    // let deviceId = localStorage.getItem('deviceId');

    // // If not, create one based on browser properties and store it
    // if (!deviceId) {
    //     const { userAgent, language } = navigator;
    //     const uniquePart = uuidv4(); // Generate a unique ID part
    //     deviceId = btoa(`${userAgent}-${language}-${uniquePart}`);
    //     localStorage.setItem('deviceId', deviceId); // Store in localStorage
    // }

    // return deviceId;
};

const generateFingerprint = async () => {
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    const deviceId = result.visitorId; // Unique identifier for the device
    console.log('Device ID:', deviceId);
    return deviceId;
};


const getUniqueDeviceId = async () => {
    // Check if deviceId exists in localStorage
    let deviceId = localStorage.getItem('deviceId');

    // If not, create one based on browser properties and store it
    if (!deviceId) {
        deviceId = await generateFingerprint();
        localStorage.setItem('deviceId', deviceId); // Store in localStorage
    }

    return deviceId;
}

const updateQueryParam = (param: string, value: string) => {
    // Get the current URL
    const url = new URL(window.location.href);

    // Update or add the query parameter
    url.searchParams.set(param, value);

    // Update the browser's URL without reloading the page
    window.history.pushState({}, '', url);
}

const  deleteQueryParam = (param: string) => {
    // Get the current URL
    const url = new URL(window.location.href);

    // Delete the query parameter
    url.searchParams.delete(param);

    // Update the browser's URL without reloading the page
    window.history.pushState({}, '', url);
}

const getQueryParams = () => {
    const query = new URLSearchParams(window.location.search);
    return query.get('type') || '';
}

export const getLastFiveItems = <T>(arr: T[], numberOfElement: number): T[] => {
    return arr.slice(-numberOfElement);
};

const logOutUser = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('activeChat');
    localStorage.removeItem('lastChat');
    localStorage.removeItem('activeChatWithInternet');
    localStorage.removeItem('lastChatWithInternet');
} 



export { 
    getUrlPath, 
    copyData,
    shareResponse, 
    convertStringToDelta, 
    handleRoute, 
    clickDomElement, 
    handleShare,
    capitalizeFirstLetter,
    openEditor,
    dateFormat,
    replaceImageElement,
    imageReplaceTextValue,
    handleTextReplace,
    convertHtmlToMarkdown,
    convertToMarkdownFormat,
    isHtmlString,
    getRegion,
    extractAssistantData,
    getDeviceId,
    openCurriculumEditor,
    openContentEditor,
    getUniqueDeviceId,
    generateFingerprint,
    updateQueryParam,
    deleteQueryParam,
    getQueryParams,
    logOutUser,
};
