/* eslint-disable @typescript-eslint/no-unused-vars */
import { Col, Form, Input, Row } from 'antd';
import { useEffect, useRef, useState } from 'react';
import AIGenerate from '@icons/generate.svg';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import { io, Socket } from 'socket.io-client';
import { BASE_URL } from '@src/utils/constants';
import { onGenerateWithAI } from '../../curriculum/functions';
import { useNavigate, useOutletContext } from 'react-router';
import { useDebouncer } from '@src/pages/dashboard/function/helper';
import { useDocumentContext } from '@src/context/DocumentContext';
import { Chats, ChatType } from '@src/pages/dashboard/endpoints';
import { useUserContext } from '@src/context/UseProvider';
import { v4 as uuidv4 } from 'uuid';
import { prompts } from '@src/utils/prompts';
import NewEditorPage from '../../essay/editor/newInstanceOfEditor';
import NewSunEditorComponent from '../../essay/sun_editor copy';
import Profile from '@assets/Avatar.svg';
import BackButton from '@src/components/BackButton';

const { TextArea } = Input;

interface ProjectFormValues {
    projectTitle: string;
    objective: string;
    methodology: string;
    timeline: string;
    executiveSummary: string;
    introduction: string;
    coreContent: string;
    keyDeliverables: string;
    keyFindings: string;
    analysis: string;
    challengesSolutions: string;
    lessonsLearned: string;
    conclusion: string;
    recommendations: string;
}

interface ProjectLoadingStates {
    executiveSummary: boolean;
    introduction: boolean;
    coreContent: boolean;
    keyDeliverables: boolean;
    keyFindings: boolean;
    analysis: boolean;
    challengesSolutions: boolean;
    lessonsLearned: boolean;
    conclusion: boolean;
    recommendations: boolean;
}

interface UserDocOn {
    documentId: string;
    users: string[];
    admin: any;
    collaborators: any[];
}

const CustomTextAreaWithButton = ({
    placeholder,
    uniqueKey,
    loadingStates,
    setLoadingStates,
    value,
    onChange,
    onClickButton,
}: {
    placeholder: string;
    uniqueKey: keyof ProjectLoadingStates;
    loadingStates: ProjectLoadingStates;
    setLoadingStates: React.Dispatch<React.SetStateAction<ProjectLoadingStates>>;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onClickButton: () => void;
}) => {
    const handleButtonClick = () => {
        setLoadingStates((prevState) => ({
            ...prevState,
            [uniqueKey]: true,
        }));

        onClickButton();
    };

    return (
        <div className="relative">
            <TextArea
                autoSize={{ minRows: 4 }}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                className="p-2 border-gray-200 focus:outline-none"
                style={{ outline: 'none', boxShadow: 'none' }}
            />
            {!loadingStates[uniqueKey] ? (
                <div
                    onClick={handleButtonClick}
                    className="cursor-pointer shadow-md rounded-[1rem] absolute top-[55px] right-[10px] py-[0.3rem] px-[1rem] bg-white text-normal text-[0.75rem] text-[var(--gmind-black)] flex gap-x-2"
                >
                    <img src={AIGenerate} alt="" />
                    <span>Use Gmind AI</span>
                </div>
            ) : (
                <div className="absolute top-[65px] right-[20px] ">
                    <CircularProgress size={15} className="w-1 h-1" />
                </div>
            )}
        </div>
    );
};

const ProjectTemplateForm = () => {
    const [form] = Form.useForm();
    const [page, setPage] = useState(1);
    const [sockets, setSockets] = useState<{ [key: string]: Socket | null }>({});
    const baseurl = BASE_URL;
    const [rememberMe, setRememberMe] = useState(false);
    const [generate, setGenerate] = useState(false);
    const [generating, setGenerating] = useState(false);
    const [histories, setHistories] = useState<any>([]);
    const [dialogVisible, setDialogVisible] = useState(false);
    // socket.io
    // const [response, setResponse] = useState('');
    const [socket, setSocket] = useState<Socket | null>(null);
    const [chats, setChats] = useState<Chats>({ role: '', content: '', type: 'gpt-4' });
    const [promptSent, setPromptSent] = useState('');
    const [newPromptSent, setNewPromptSent] = useState('');
    const [chatList, setChatList] = useState<ChatType[]>([]);
    const [isMobile, setIsMobile] = useState(true);
    const UrlRef = useRef<string>('');
    const [showDownload, setShowDownload] = useState<boolean>(false);
    const [showShareModal, setShowShareModal] = useState<boolean>(false);
    const [showEditorButton, setShowEditorButton] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [canNavigate, setCanNavigate] = useState(false);
    const StreamId = useRef<any>();
    const DocumentId = useRef('');
    const [topic, setTopic] = useState('');
    const [audience, setAudience] = useState('');
    const [docId, setDocId] = useState<string | undefined>(undefined);
    const [showingHistory, setShowingHistory] = useState(false);
    const [streamEndMessage, setStreamEndMessage] = useState<ChatType>({ role: 'assistant', content: '' });

    const {
        setShowMobileHistory,
        setShowTop,
        setShowSidebar,
        setRightComponentName,
        setRightComponentPlaceholder,
        setRightComponentData,
        setHistory,

        setShowPagePath,

        setCanNavigateHome,
        setShowExpiredModal,
        setShowCompleteProfileModal,
        setIsAffiliate,
        setMenu,
        setShowInput,
        setShowHistoryOptions,
        setRightComponentClick,
        setOnDetailsFetched,
        setOnDeleteAllHistory,
        setOnDeleteHistory,
        setOnClickPage,
        setSelectedHistory,
        setValue,
        setShowRightComponent,
        setSchoolAdminPageName,
        setShowSchoolHeader,
        setShowHistory,
        response,
        setResponse,
        details,
    } = useOutletContext<any>();
    const pageHistory = 'project description';
    const navigate = useNavigate();

    const bottomRef = useRef<HTMLDivElement>(null);
    const editorInstanceRef = useRef<any>();
    const [highlightedTexts, setHighlightedTexts] = useState<string>('');
    const [latestRespone, setLatestRespone] = useState<string>('');
    const { userDetails } = useUserContext();
    const { error, loading, fetchDocument, document: textDocument, documentResponse } = useDocumentContext();
    const [editorContent, setEditorContent] = useState<any>('');
    const [translatedContent, setTranslatedContent] = useState<any>();
    const [humanizedContent, setHumanizedContent] = useState<any>();
    const [userOnDoc, setUserOnDoc] = useState<UserDocOn>();
    const [isNotLoggedIn, setIsNotLoggedIn] = useState(false);

    const [loadingStates, setLoadingStates] = useState<ProjectLoadingStates>({
        executiveSummary: false,
        introduction: false,
        coreContent: false,
        keyDeliverables: false,
        keyFindings: false,
        analysis: false,
        challengesSolutions: false,
        lessonsLearned: false,
        conclusion: false,
        recommendations: false,
    });

    const socketKey = ['executiveSummary', 'introduction', 'coreContent', 'keyDeliverables'];

    const [pageOneValues, setPageOneValues] = useState<{ [key: string]: string }>({
        projectTitle: '',
        objective: '',
        methodology: '',
        timeline: '',
    });

    useEffect(() => {
        const newSocket = io(baseurl.slice(0, -2));
        setSocket(newSocket);
        return () => {
            newSocket.close();
        };
    }, []);

    // const {
    //     details: userDetails,
    // } = useOutletContext<any>();

    useEffect(() => {
        const newSockets: { [key: string]: Socket } = {};
        socketKey.forEach((item) => {
            newSockets[item] = io(baseurl.slice(0, -2));
        });
        setSockets(newSockets);

        return () => {
            Object.values(newSockets).forEach((socket) => {
                socket.close();
            });
        };
    }, []);

    const onGenerateHandler = async (message: string) => {
        if (!message) {
            toast.error("Input can't be empty");
            return;
        }
        toast.info('Please sit tight, your beautiful content is on its way.');
        // setIsTyping(true);
        let msgs = chats;
        msgs = { role: 'user', content: message, type: 'gpt-4' };
        setChats(msgs);
        setNewPromptSent('');
        setResponse('');
        setIsTyping(true);

        try {
            socket?.emit('data', {
                data: {
                    messages: [
                        {
                            role: 'system',
                            //   content: `You are G-Mind. You can help with all educational or academic questions or tasks`,
                            content: prompts.projectReportGenerator,
                            type: 'gpt-4',
                        },
                        msgs,
                    ],
                    userId: details?.userId,
                },
            });
        } catch (error) {
            // setIsTyping(false);
        } finally {
            // setIsTyping(false);
        }
    };

    const regenerate = () => {
        onGenerateHandler(promptSent);
        setGenerate(true);
        setGenerating(true);
        setIsTyping(true);
        const timeoutId = setTimeout(() => {
            setGenerating(false);
        }, 3000);
    };

    useEffect(() => {
        if (!socket) return;

        socket.on('data', (data: string) => {
            setResponse((response: string) => response + data);
        });

        socket.on('stream_end', async (data: { streamId: string; assistant: ChatType }) => {
            console.log(data);
            const { streamId, assistant } = data;
            setStreamEndMessage(assistant);
            setShowEditorButton(true);
            setIsTyping(false);
            setCanNavigate(true);

            // handle stream end
            const uuid = uuidv4();
            setDocId(uuid);

            // const user = JSON.parse(localStorage.getItem('user') || '');
            // try {
            //     socket?.emit('store-document', {
            //         id: uuid,
            //         title: promptSent,
            //         value: data.assistant,
            //         owner_id: user?.id,
            //     });
            // } catch (error) {
            //     socket?.emit('store-document', {
            //         id: uuid,
            //         title: promptSent,
            //         value: data.assistant,
            //     });
            // }
            // socket?.emit('get-documents', user?.id);
        });

        socket.on('user_log_in', (data: string) => {
            console.log(data);

            setIsNotLoggedIn(true);
        });

        return () => {
            socket.off('message');
        };
    }, [socket]);

    const sendChanges = useDebouncer((documentId: string, userId: string, data: string) => {
        console.log('sendChanges', documentId, userId, data);
        socket?.emit('send-changes', {
            documentId,
            userId,
            data,
        });
    }, 1000);

    useEffect(() => {
        socketKey.forEach((item) => {
            const socket = sockets[item];
            if (!socket) return;

            const handleData = (data: string) => {
                const previousValue = form.getFieldValue(item);
                form.setFieldValue(item, previousValue + data);
            };

            const handleStreamEnd = () => {
                setLoadingStates((prevState) => ({
                    ...prevState,
                    [item]: false,
                }));
            };

            socket.on('data', handleData);
            socket.on('stream_end', handleStreamEnd);
            return () => {
                socket.off('data', handleData);
                socket.off('stream_end', handleData);
            };
        });
    }, [sockets]);

    const onFinish = (values: any) => {
        const projectTitle = pageOneValues['projectTitle'];
        const objective = pageOneValues['projectTitle'];
        const methodology = pageOneValues['projectTitle'];
        const timeline = pageOneValues['projectTitle'];

        const { executiveSummary, introduction, coreContent, keyDeliverables } = values;

        if (
            !projectTitle ||
            !objective ||
            !methodology ||
            !timeline ||
            !executiveSummary ||
            !introduction ||
            !coreContent ||
            !keyDeliverables
        ) {
            toast.error('Please fill all the required fields before generating.');
            return;
        }

        const promptMessageInfo = `
            Project Title: ${projectTitle}
            Objective: ${objective}
            Methodology: ${methodology}
            Timeline: ${timeline}
            Executive Summary: ${executiveSummary}
            Introduction: ${introduction}
            Core Content: ${coreContent}
            Key Deliverables: ${keyDeliverables}
        `;

        const promptMessage = `Generate a detailed, professional, and well-structured project report with the following details: ${promptMessageInfo}. Kindly ensure to follow the details provided`;

        console.log(promptMessage);
        setPromptSent(promptMessage);
        onGenerateHandler(promptMessage);
        setGenerate(true);
        setGenerating(true);
        const timeoutId = setTimeout(() => {
            setGenerating(false);
        }, 3000);
    };

    const handlePrevious = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    useEffect(() => {
        if (generate || showingHistory) {
            setShowTop(false);
            setShowSidebar(false);
        } else {
            setShowTop(true);
            setShowSidebar(true);
        }
    }, [generate, showingHistory]);

    return (
        <div className="flex flex-col h-screen pt-5 px-[2rem] pb-5">
            {!generate && (
                <BackButton
                    onclick={() => {
                        navigate(-1);
                    }}
                />
            )}
            {!generate && !showingHistory ? (
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                    initialValues={{
                        projectTitle: '',
                        objective: '',
                        methodology: '',
                        timeline: '',
                        executiveSummary: '',
                        introduction: '',
                        coreContent: '',
                        keyDeliverables: '',
                        keyFindings: '',
                        analysis: '',
                        challengesSolutions: '',
                        lessonsLearned: '',
                        conclusion: '',
                        recommendations: '',
                    }}
                >
                    <h1 className="text-xl dark:text-white font-bold font-Poppins mb-4 mt-4">
                        Project Template Writer
                    </h1>
                    <p className="text-md  dark:text-gray-300 font-Poppins mb-4">
                        Create a comprehensive project report with the help of this tool.
                    </p>

                    {page === 1 && (
                        <>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <Form.Item
                                        label="Project Title"
                                        name="projectTitle"
                                        rules={[{ required: true, message: 'Project Title is required' }]}
                                    >
                                        <Input placeholder="Enter project title" />
                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item
                                        label="Objective"
                                        name="objective"
                                        rules={[{ required: true, message: 'Objective is required' }]}
                                    >
                                        <Input placeholder="Enter project objective" />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <Form.Item
                                        label="Methodology"
                                        name="methodology"
                                        rules={[{ required: true, message: 'Methodology is required' }]}
                                    >
                                        <Input placeholder="Enter project methodology" />
                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item
                                        label="Timeline"
                                        name="timeline"
                                        rules={[{ required: true, message: 'Timeline is required' }]}
                                    >
                                        <Input placeholder="Enter project timeline" />
                                    </Form.Item>
                                </div>
                            </div>

                            <Row gutter={16}>
                                <Col span={24}>
                                    <button
                                        onClick={() =>
                                            form.validateFields().then(() => {
                                                setPageOneValues({
                                                    projectTitle: form.getFieldValue('projectTitle'),
                                                    objective: form.getFieldValue('objective'),
                                                    methodology: form.getFieldValue('methodology'),
                                                    timeline: form.getFieldValue('timeline'),
                                                });
                                                setPage(page + 1);
                                            })
                                        }
                                        className="w-full md:w-[8rem] bg-customOrange text-white rounded p-2"
                                    >
                                        Next
                                    </button>
                                </Col>
                            </Row>
                        </>
                    )}
                    {page === 2 && (
                        <>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <Form.Item
                                        label="Executive Summary"
                                        name="executiveSummary"
                                        rules={[{ required: true, message: 'Executive Summary is required' }]}
                                    >
                                        <CustomTextAreaWithButton
                                            placeholder="Let Gmind AI generate executive summary"
                                            uniqueKey="executiveSummary"
                                            loadingStates={loadingStates}
                                            setLoadingStates={setLoadingStates}
                                            value={form.getFieldValue('executiveSummary')}
                                            onChange={(e) => form.setFieldValue('executiveSummary', e.target.value)}
                                            onClickButton={() => {
                                                form.setFieldValue('executiveSummary', '');
                                                let prompt = `Generate an executive summary with not more than 500 characters for this project named ${pageOneValues['projectTitle']}`;
                                                const socket = sockets['executiveSummary'];
                                                onGenerateWithAI(socket, prompt, userDetails?.userId ?? '');
                                            }}
                                        />
                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item
                                        label="Introduction"
                                        name="introduction"
                                        rules={[{ required: true, message: 'Introduction is required' }]}
                                    >
                                        <CustomTextAreaWithButton
                                            placeholder="Let Gmind AI generate introduction"
                                            uniqueKey="introduction"
                                            loadingStates={loadingStates}
                                            setLoadingStates={setLoadingStates}
                                            value={form.getFieldValue('introduction')}
                                            onChange={(e) => form.setFieldValue('introduction', e.target.value)}
                                            onClickButton={() => {
                                                form.setFieldValue('introduction', '');
                                                let prompt = `Generate an introduction with not more than 400 characters for this project named ${pageOneValues['projectTitle']} with objective  ${pageOneValues['objective']}`;
                                                const socket = sockets['introduction'];
                                                onGenerateWithAI(socket, prompt, userDetails?.userId ?? '');
                                            }}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <Form.Item
                                        label="Core Content"
                                        name="coreContent"
                                        rules={[{ required: true, message: 'Core Content is required' }]}
                                    >
                                        <CustomTextAreaWithButton
                                            placeholder="Let Gmind AI generate core content"
                                            uniqueKey="coreContent"
                                            loadingStates={loadingStates}
                                            setLoadingStates={setLoadingStates}
                                            value={form.getFieldValue('coreContent')}
                                            onChange={(e) => form.setFieldValue('coreContent', e.target.value)}
                                            onClickButton={() => {
                                                form.setFieldValue('coreContent', '');
                                                let prompt = `
                                            Generate the Core Content of this  project named ${pageOneValues['projectTitle']} with this details.
                                            Objective: ${pageOneValues['objective']}
                                            Methodology: ${pageOneValues['methodology']}
                                            Timeline: ${pageOneValues['timeline']}
                                            Ensure your response is  not more than 600 characters
                                        `;
                                                const socket = sockets['coreContent'];
                                                onGenerateWithAI(socket, prompt, userDetails?.userId ?? '');
                                            }}
                                        />
                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item
                                        label="Key Deliverables"
                                        name="keyDeliverables"
                                        rules={[{ required: true, message: 'Key Deliverables are required' }]}
                                    >
                                        <CustomTextAreaWithButton
                                            placeholder="Let Gmind AI generate key deliverables"
                                            uniqueKey="keyDeliverables"
                                            loadingStates={loadingStates}
                                            setLoadingStates={setLoadingStates}
                                            value={form.getFieldValue('keyDeliverables')}
                                            onChange={(e) => form.setFieldValue('keyDeliverables', e.target.value)}
                                            onClickButton={() => {
                                                form.setFieldValue('keyDeliverables', '');
                                                let prompt = `
                                            Generate the Key Deliverables of this project named ${pageOneValues['projectTitle']} with this details.
                                            Objective: ${pageOneValues['objective']}
                                            Methodology: ${pageOneValues['methodology']}
                                            Timeline: ${pageOneValues['timeline']}
                                            Ensure your response is  not more than 600 characters
                                            Ensure the deliverables are well itemized
                                        `;
                                                const socket = sockets['keyDeliverables'];
                                                onGenerateWithAI(socket, prompt, userDetails?.userId ?? '');
                                            }}
                                        />
                                    </Form.Item>
                                </div>
                            </div>

                            <Row gutter={16}>
                                <Col span={24} className="flex justify-between">
                                    <button
                                        onClick={handlePrevious}
                                        className="w-full md:w-[8rem] bg-transparent border border-customOrange text-customOrange rounded p-2"
                                    >
                                        Previous
                                    </button>
                                    <button
                                        type="submit"
                                        className="w-full md:w-[8rem] bg-customOrange text-white rounded p-2"
                                    >
                                        Generate
                                    </button>
                                </Col>
                            </Row>
                        </>
                    )}
                </Form>
            ) : (
                <div className="w-full flex flex-col gap-0  h-full overflow-auto">
                    <NewEditorPage
                        editorInstanceRef={editorInstanceRef}
                        regenerate={regenerate}
                        content={
                            <NewSunEditorComponent
                                latestRespone={latestRespone}
                                setLatestRespone={setLatestRespone}
                                highlightedTexts={highlightedTexts}
                                setHighlightedTexts={setHighlightedTexts}
                                editorInstanceRef={editorInstanceRef}
                                // humanizeded_content={Document.current?.data.ops}
                                // hasAccess={hasAccess}
                                hasAccess={!isTyping && (documentResponse?.role ?? '') !== 'view' ? true : false}
                                initial_content={response}
                                onChangeEditorContent={(e: string) => {
                                    setEditorContent(e);
                                    const newtext = e;
                                    console.log('newtext', newtext);

                                    console.log('docId', docId);
                                    console.log('userDetails?.userId', userDetails?.userId);

                                    sendChanges(docId, userDetails?.userId, e);
                                }}
                            />
                        }
                        showTopNavBar={false}
                        showInvite={true}
                        isTyping={isTyping}
                        Profile={Profile}
                        promptSent={promptSent}
                        showingHistory={showingHistory}
                        setGenerate={setGenerate}
                        setShowingHistory={setShowingHistory}
                        generate={generate}
                        setEditorContent={setEditorContent}
                        editorContent={editorContent}
                        translatedContent={translatedContent}
                        setTranslatedContent={setTranslatedContent}
                        humanizedContent={humanizedContent}
                        setHumanizedContent={setHumanizedContent}
                        setUserOnDoc={setUserOnDoc}
                        userOnDoc={userOnDoc}
                        highlightedTexts={highlightedTexts}
                        setHighlightedTexts={setHighlightedTexts}
                        latestRespone={latestRespone}
                        setLatestRespone={setHighlightedTexts}
                        docId={undefined}
                        setDocId={() => {}}
                    />
                </div>
            )}
        </div>
    );
};

export default ProjectTemplateForm;
