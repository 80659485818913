import { Form, Input } from 'antd';
import {  SocialMediaProps } from '../interface';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { InputTypeProps } from '../../assessment_2/interface';
import { socialMedias, toneOptions } from '../../curriculum/constants';
import { Select } from '@src/pages/dashboard/components/Select';
import { prompts } from '@src/utils/prompts';
import { io, Socket } from 'socket.io-client';
import AIGenerate from '@icons/generate.svg';
import { onGenerateWithAI } from '../../curriculum/functions';
import { BASE_URL } from '@src/utils/constants';
import { useOutletContext } from 'react-router';

const SocialMediaContent = ({ onGenerate,  }: { onGenerate: (e: any, p: boolean, t: string) => void }) => {
    const [socialInput, setSocialInput] = useState<{ [key: string]: string }>({
        title: '',
        media: '',
        tone: '',
        message: '',
        hastags: '',
        action: '',
    });
    const [sockets, setSockets] = useState<{ [key: string]: Socket | null }>({});
    const [inputTyping, setInputTyping] = useState<{ [key: string]: boolean }>({});
    const [visibility, setVisibility] = useState<{ [key: string]: boolean }>({});

    const handleOnChange = (value: string, keyString: string) => {
        setSocialInput({
            ...socialInput,
            [keyString]: value,
        });
    };

    const handleOnChangeFromAI = (value: string, key: string) => {
        setSocialInput((prevFormData) => ({
            ...prevFormData,
            [key]: prevFormData[key] + value,
        }));
    };

    const setTyping = (key: string, value: boolean) => {
        setInputTyping((prevData) => ({
            ...prevData,
            [key]: value,
        }));
    };

    const setItemVisibility = (key: string, value: boolean) => {
        setVisibility((prevData) => ({
            ...prevData,
            [key]: value,
        }));
    };

    const inputData: Array<InputTypeProps> = [
        {
            name: 'Title*',
            placeholder: 'Enter a title for your social media post',
            value: 'title',
            onChange: (value, key) => handleOnChange(value, key),
            type: 'input',
            required: true,
        },
        {
            name: 'Social Media Platform*',
            placeholder: 'e.g Physics, Project Management etc',
            value: 'media',
            onChange: (value, key) => handleOnChange(value, key),
            type: 'select',
            options: socialMedias,
            required: true,
        },
        {
            name: 'Tone*',
            placeholder: 'Enter tone of your social media test',
            value: 'tone',
            onChange: (value, key) => handleOnChange(value, key),
            type: 'select',
            options: toneOptions,
            required: true,
        },
        {
            name: 'Main Message*',
            placeholder: 'Main message of your media content',
            value: 'message',
            onChange: (value, key) => handleOnChange(value, key),
            type: 'input',
            required: true,
        },
        {
            name: 'Hashtags*',
            placeholder: 'Enter content hastags',
            value: 'hastags',
            onChange: (value, key) => handleOnChange(value, key),
            type: 'input',
            required: true,
        },
        {
            name: 'Call to Action',
            placeholder: 'Enter call action',
            value: 'action',
            onChange: (value, key) => handleOnChange(value, key),
            type: 'textarea',
            minRows: 2,
            onGenerate: (e, t) => {
                const message = `Generate a short call to action for this social media post with title ${t}`;
                onGenerateWithAI(sockets[e], message, userDetails?.userId ?? "" ,prompts.socialContent);
            },
        },
    ];

    const {
        details: userDetails,
    } = useOutletContext<any>();

    useEffect(() => {
        const newSockets: { [key: string]: Socket } = {};
        const newTyping: { [key: string]: boolean } = {};
        const visibility: { [key: string]: boolean } = {};
        inputData.forEach((item) => {
            if (item.type == 'textarea') {
                newSockets[item.name] = io(BASE_URL.slice(0, -2));
                newTyping[item.name] = false;
                visibility[item.name] = true;
            }
        });
        setSockets(newSockets);
        setInputTyping(newTyping);
        setVisibility(visibility);

        return () => {
            Object.values(newSockets).forEach((socket) => {
                socket.close();
            });
        };
    }, []);

    useEffect(() => {
        inputData.forEach((item) => {
            if (item.type == 'textarea') {
                const socket = sockets[item.name];
                if (!socket) return;

                const handleData = (data: string) => {
                    handleOnChangeFromAI(data, item.value);
                };

                const handleStreamEnd = () => {
                    setTyping(item.name, false);
                };

                socket.on('data', handleData);
                socket.on('stream_end', handleStreamEnd);
                return () => {
                    socket.off('data', handleData);
                    socket.off('stream_end', handleData);
                };
            }
        });
    }, [sockets]);

    const onFinish = () => {
        let promptMessage = '';

        let canProcced: boolean = true;
        let baseToSend = '';
        inputData.forEach((item) => {
            if (!socialInput[item.value] && item.required == true) {
                canProcced = false;
                return;
            } else {
                baseToSend += `${item.name.replace('*', '')}: ${socialInput[item.value]}\n`;
            }
        });

        if (!canProcced) {
            toast.error('Kindly enter all values');
            return;
        }

        promptMessage = `Generate a social media content with the following details: ${baseToSend}. Ensure all information provided are followed`;

        onGenerate(promptMessage, false, promptMessage);
    };

    return (
        <div className="w-full flex flex-col gap-0 h-auto max-h-full overflow-auto">
            <div className="w-full flex flex-row gap-0 ">
                <div className="w-full flex flex-col gap-0  px-3 mb:px-10 mb-10">
                    <div className="text-[1.5rem]  md:text-[2.75rem] dark:text-white curriculum--title">Social Media Content</div>
                    <div className="text-[1rem] dark:text-gray-300 md:text-[0.875rem] mb-3 curriculum--subtitle">
                        Provide us the information requested below to get your social media content ready.
                    </div>
                    <Form layout="vertical" className="flex flex-col md:flex-row flex-wrap gap-x-5" onFinish={onFinish}>
                        <div className="flex gap-x-3 flex-col md:flex-row flex-wrap w-full items-center">
                            {inputData.map((item, index) => (
                                <Form.Item
                                    key={index}
                                    className="w-full md:w-[48%]"
                                    name={item.name}
                                    label={<label className="curriculum--label">{item.name}</label>}
                                    rules={[
                                        {
                                            message: `${item.name} is required`,
                                            required: false,
                                        },
                                    ]}
                                >
                                    {item.type == 'input' && (
                                        <Input
                                            className="w-full rounded-[0.25rem] outlline-none placeholder-gray-700"
                                            style={{
                                              
                                              
                                                backgroundColor: 'transparent',
                                             
                                            }}
                                            value={socialInput[item.value]}
                                            onChange={(e) => item.onChange(e.target.value, item.value)}
                                            placeholder={item.placeholder}
                                        />
                                    )}
                                    {item.type == 'select' && (
                                        <Select
                                            className="w-full rounded-[0.25rem] placeholder-gray-700  "
                                            onSelect={(e) => item.onChange(e, item.value)}
                                            options={item.options ?? []}
                                        />
                                    )}
                                    {item.type == 'textarea' && (
                                        <div className="relative">
                                            <Input.TextArea
                                                className="w-full rounded-[0.25rem] placeholder-gray-700"
                                                onChange={(e) => item.onChange(e.target.value, item.value)}
                                                style={{
                                                    border: '1px solid gray',
                                                    borderRadius: '4px',
                                                    backgroundColor: 'transparent',
                                                    padding: '7px 10px',
                                                }}
                                                value={socialInput[item.value]}
                                                placeholder={item.placeholder}
                                                autoSize={{ minRows: item.minRows }}
                                            />
                                            {item.onGenerate && visibility[item.name] && (
                                                <div
                                                    onClick={() => {
                                                        item.onGenerate!(item.name, item.name);
                                                        setItemVisibility(item.name, false);
                                                    }}
                                                    className="cursor-pointer shadow-md rounded-[1rem] absolute top-[20px] right-[15px] py-[0.3rem] px-[1rem] bg-white text-normal text-[0.75rem] text-[var(--gmind-black)] flex gap-x-2"
                                                >
                                                    <img src={AIGenerate} alt="" />
                                                    <span>Use Gmind AI</span>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </Form.Item>
                            ))}
                        </div>
                        <button type="submit" className="text-white transcribe">
                            Generate
                        </button>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default SocialMediaContent;
