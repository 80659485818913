export class AppRoutes {
    page: string;
    route: string;

    constructor({ page, route }: { page: string; route: string }) {
        this.page = page;
        this.route = route;
    }
}

export class AcquisitionRouteValues {
    static home = new AppRoutes({ page: 'home', route: '/index/home' });
    static login = new AppRoutes({ page: 'login', route: '/index/login' });
    static company = new AppRoutes({ page: 'company', route: '/index/company' });
    static forgotPassword = new AppRoutes({ page: 'forgot_password', route: '/index/forgot_password' });
    static getStarted = new AppRoutes({ page: 'get-started', route: '/index/get-started' });
    static cookies = new AppRoutes({ page: 'cookies', route: '/index/cookies' });
    static termsOfService = new AppRoutes({ page: 'terms-of-service', route: '/index/terms-of-service' });
    static privacyPolicy = new AppRoutes({ page: 'privacy-policy', route: '/index/privacy-policy' });
    static pricing = new AppRoutes({ page: 'pricing', route: '/index/pricing' });
    static success = new AppRoutes({ page: 'success', route: '/index/success' });
    static cancel = new AppRoutes({ page: 'cancel', route: '/index/cancel' });
    static verifyMail = new AppRoutes({ page: 'verify_mail', route: '/index/verify_mail' });
    static verify = new AppRoutes({ page: 'verify', route: '/index/verify' });
    static productivity = new AppRoutes({ page: 'productivity', route: '/index/productivity' });
    static creation = new AppRoutes({ page: 'creation', route: '/index/creation' });
    static assistant = new AppRoutes({ page: 'assistant', route: '/index/assistant' });
    static researchers = new AppRoutes({ page: 'researchers', route: '/index/researchers' });
    static status = new AppRoutes({ page: 'status', route: '/index/status' });
}

export class AffiliateRouteValues {
    static dashboard = new AppRoutes({ page: 'dashboard', route: '/affiliate/dashboard' });
    static referrals = new AppRoutes({ page: 'referrals', route: '/affiliate/referrals' });
    static transactions = new AppRoutes({ page: 'transactions', route: '/affiliate/transactions' });
}

export class ChatDashboardRouteValues {
    static chat = new AppRoutes({ page: '', route: '/' });
    static smartChat = new AppRoutes({ page: 'smart_chat', route: '/smart_chat' });
    static writerGenie = new AppRoutes({ page: 'writer_genie', route: '/writer_genie' });
    static assessment = new AppRoutes({ page: 'assessment', route: '/assessment' });
    static article = new AppRoutes({ page: 'article', route: '/article' });
    static articleEditor = new AppRoutes({ page: 'article/editor', route: '/article/editor' });
    static curriculum = new AppRoutes({ page: 'curriculum', route: '/curriculum' });
    static keywordResearcher = new AppRoutes({ page: 'keyword_researcher', route: '/keyword_researcher' });
    static coverLetterWriter = new AppRoutes({ page: 'cover_letter_writer', route: '/cover_letter_writer' });
    static interviewPrep = new AppRoutes({ page: 'interview_prep', route: '/interview_prep' });
    static speechSynthesizer = new AppRoutes({ page: 'speech_synthesizer', route: '/speech_synthesizer' });
    static videoMode = new AppRoutes({ page: 'video_mode', route: '/video_mode' });
    static imageMaker = new AppRoutes({ page: 'image_maker', route: '/image_maker' });
    static imageMakerEdit = new AppRoutes({ page: 'image_maker/edit', route: '/image_maker/edit' });
    static flowChart = new AppRoutes({ page: 'flow_chart', route: '/flow_chart' });
    static resumeEnhancer = new AppRoutes({ page: 'resume_enhancer', route: '/resume_enhancer' });
    static research = new AppRoutes({ page: 'research', route: '/research' });
    static educatorResources = new AppRoutes({ page: 'educator_resources', route: '/educator_resources' });
    static productDescription = new AppRoutes({ page: 'product_description', route: '/product_description' });
    static mediaResources = new AppRoutes({ page: 'media_resources', route: '/media_resources' });
    static businessResources = new AppRoutes({ page: 'business_resources', route: '/business_resources' });
    static metaTag = new AppRoutes({ page: 'meta_tag', route: '/meta_tag' });
    static youtubeTranscript = new AppRoutes({ page: 'youtube_transcript', route: '/youtube_transcript' });
    static schoolProfile = new AppRoutes({ page: 'school-profile', route: '/school-profile' });
    static studentResources = new AppRoutes({ page: 'student_resources', route: '/student_resources' });
    static pricing = new AppRoutes({ page: 'pricing', route: '/pricing' });
    static aPlusEssay = new AppRoutes({ page: 'a_plus_essay', route: '/a_plus_essay' });
    static quizMaker = new AppRoutes({ page: 'quiz_maker', route: '/quiz_maker' });
    static visualizer = new AppRoutes({ page: 'visualizer', route: '/visualizer' });
    static assignmentHelper = new AppRoutes({ page: 'assignment_helper', route: '/assignment_helper' });
    static paymentSuccess = new AppRoutes({ page: 'payment-success', route: '/payment-success' });
    static profile = new AppRoutes({ page: 'profile', route: '/profile' });
    static customerPersonas = new AppRoutes({ page: "customer-personas", route: "/customer-personas" });
    static noteSummarizer = new AppRoutes({ page: "note-summarizer", route: "/note-summarizer" });
    static lesson = new AppRoutes({ page: "lesson-planner", route: "/lesson-planner" });
    static syllabus = new AppRoutes({ page: "syllabus-generator", route: "/syllabus-generator" });
    static activity = new AppRoutes({ page: "activity-generator", route: "/activity-generator" });

    // School and Corporate
    static schoolAdmin = new AppRoutes({ page: 'school-admin', route: '/school-admin' });
    static schoolAdminTicket = new AppRoutes({ page: 'school-admin/ticket', route: '/school-admin/ticket' });
    static schoolAdminTeacher = new AppRoutes({
        page: 'school-admin/school-teacher',
        route: '/school-admin/school-teacher',
    });
    static schoolAdminAddTeacher = new AppRoutes({
        page: 'school-admin/school-teacher/add-teacher',
        route: '/school-admin/school-teacher/add-teacher',
    });
    static corporateAdmin = new AppRoutes({ page: 'corporate-admin', route: '/corporate-admin' });
    static transcribe = new AppRoutes({ page: 'transcriber', route: '/transcriber' });
    static translate = new AppRoutes({ page: 'text_translator', route: '/text_translator' });
    static dataAnalysis = new AppRoutes({ page: 'data_visualizer_tool', route: '/data_visualizer_tool' });
    static corporateAdminStaff = new AppRoutes({ page: 'corporate-admin/staff', route: '/corporate-admin/staff' });
    static corporateAdminTicket = new AppRoutes({ page: 'corporate-admin/ticket', route: '/corporate-admin/ticket' });
    static corporateAdminAddStaff = new AppRoutes({
        page: 'corporate-admin/staff/add-staff',
        route: '/corporate-admin/staff/add-staff',
    });
}

const extractPages = (routeClass: any): { [key: string]: string } => {
    const routes: { [key: string]: string } = {};

    Object.keys(routeClass).forEach((key) => {
        if (routeClass[key] instanceof AppRoutes) {
            routes[key] = routeClass[key].page;
        }
    });

    return routes;
};

const extractRoutes = (routeClass: any): { [key: string]: string } => {
    const routes: { [key: string]: string } = {};

    Object.keys(routeClass).forEach((key) => {
        if (routeClass[key] instanceof AppRoutes) {
            routes[key] = routeClass[key].route;
        }
    });

    return routes;
};

export const chatRoutePages = extractPages(ChatDashboardRouteValues);
export const chatRoutes = extractRoutes(ChatDashboardRouteValues);

export const acquisitionRoutePages = extractPages(AcquisitionRouteValues);
export const acquisitionRoutes = extractRoutes(AcquisitionRouteValues);

export const affiliateRoutePages = extractPages(AffiliateRouteValues);
export const affiliateRoutes = extractRoutes(AffiliateRouteValues);
