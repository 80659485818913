import React, { useEffect } from 'react';
import { Dropdown, Button, Menu } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';

interface User {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    profileImg: string;
  }

interface UserDocOn {
    documentId: string;
    users: string[];
    admin: any;
    collaborators: User[];
}

const CollaboratorDropdown = ({ userOnDoc }:{ userOnDoc: UserDocOn }) => {
    useEffect(() => {
        console.log(userOnDoc);
    }, [userOnDoc]);
  const renderCollaborators = () => (
    <Menu>
      <Menu.Item key="admin" disabled>
        <div className="flex items-center justify-between gap-3">
        <div className="flex items-center gap-3">
          {
            userOnDoc?.admin?.profileImg ? ( 
              <img
                src={userOnDoc?.admin?.profileImg}
                alt="Admin Profile"
                className="w-10 h-10 rounded-full"
              />
            ): (
              <>
                { (userOnDoc.admin?.email ?? "") === "" ? "" :userOnDoc.admin?.email.charAt(0).toUpperCase()}
              </>
            )
          }
          <span>{userOnDoc?.admin?.firstName ?? 'Admin'}</span>
        
        </div>
        <span className="text-gray-200 bg-green-500 shadow shadow-md py-[0.5px] px-[3px] text-[10px] rounded-lg ">Admin</span>
        </div>
      </Menu.Item>
      {userOnDoc?.collaborators?.map((collaborator, index) => (
        <Menu.Item key={index}>
          <div className="flex items-center gap-3">
            <div className="w-10 h-10 bg-red-500 rounded-full flex items-center justify-center text-white">
              {collaborator.email.charAt(0).toUpperCase()}
            </div>
            <span>{collaborator.email}</span>
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className="flex justify-start">
      <Dropdown overlay={renderCollaborators} trigger={["click"]}>
        <button className='bg-customOrange text-white font-bold py-2 px-3  h-[34px] rounded-md flex items-center gap-2'>
          Collaborators <DownOutlined />
        </button>
      </Dropdown>
    </div>
  );
};

export default CollaboratorDropdown;
