import React, { Suspense } from 'react';
import { Route } from 'react-router-dom';
import Loading from './components/Loading/index.tsx';
import BusinessResources from './pages/dashboard/pages/business_resources/index.tsx';
import Curriculum from './pages/dashboard/pages/curriculum/index.tsx';
import MediaResources from './pages/dashboard/pages/media_resources/index.tsx';
import SchoolLetters from './pages/dashboard/pages/school_letters copy/index.tsx';
import Generate from './pages/dashboard/pages/generate/index.tsx';
import SchoolResources from './pages/dashboard/pages/school_resources/index.tsx';
import APlusEssay from './pages/dashboard/pages/school_resources/sub_pages/a+_essay/index.tsx';
import QuizMaker from './pages/dashboard/pages/school_resources/sub_pages/quiz_maker.tsx';
import ArticleWriter from './pages/dashboard/pages/article/a+_essay/index.tsx';
import ResearchWriter from './pages/dashboard/pages/make_research/a+_essay/index.tsx';
import AssignmentSolver from './pages/dashboard/pages/school_resources/sub_pages/assignment_helper/index.tsx';
import YouTubeTranscript from './pages/dashboard/pages/school_resources/sub_pages/youtube_transcript.tsx';
import MetaTagGenerator from './pages/dashboard/pages/business_resources/sub_pages/meta_tag.tsx';
import ProductDescriptionGenerator from './pages/dashboard/pages/business_resources/sub_pages/project_description.tsx';
import FlowChartAnalyzer from './pages/dashboard/pages/media_resources/sub_pages/flow_chart.tsx';
import KeywordResearcher from './pages/dashboard/pages/media_resources/sub_pages/keyword_research.tsx';
import CoverLetterWritter from './pages/dashboard/pages/school_resources/sub_pages/cover_letter.tsx';
import InterviewPrep from './pages/dashboard/pages/school_resources/sub_pages/interview.tsx';
import { chatRoutePages } from './utils/app_routes.ts';
import ResumeEnhancer from './pages/dashboard/pages/school_resources/sub_pages/resume_enhancer.tsx';
import CustomerBuilder from './pages/dashboard/pages/school_resources/sub_pages/customer.tsx';
import NoteSummarizer from './pages/dashboard/pages/school_resources/sub_pages/note_summary.tsx';
import ActivityGenerator from './pages/dashboard/pages/curriculum/components/activity_generator.tsx';
import LessonPlanner from './pages/dashboard/pages/curriculum/components/lesson_planner.tsx';
import SyllabusScreen from './pages/dashboard/pages/curriculum/components/syllabus_screen.tsx';
import { CreateAcount } from './pages/acquisition/signup/components/CreateAcount.tsx';
import CreateChart from './pages/dashboard/pages/data_visualizer/sub_pages/create_chart_analysis.tsx';
import ProjectTemplateForm from './pages/dashboard/pages/business_resources/sub_pages/project.tsx';
import Transcriber from './pages/dashboard/pages/transcriber/index.tsx';
import TextTranslator from './pages/dashboard/pages/business_resources/sub_pages/translator.tsx';


const Chat = React.lazy(() => import('./pages/dashboard/pages/chat/index.tsx'));
const SmartSearchPage = React.lazy(() => import('./pages/dashboard/pages/chat/smart_search.tsx'));
const NotFoundPage = React.lazy(() => import('./pages/404/index.tsx'));
const EditorPage = React.lazy(() => import('./pages/dashboard/pages/essay/editor/index.tsx'));
const Image = React.lazy(() => import('./pages/dashboard/image/index.tsx'));
const Assessment = React.lazy(() => import('./pages/dashboard/pages/assessment_2/index.tsx'));
const ComingSoonPage = React.lazy(() => import('./pages/dashboard/pages/coming_soon/index.tsx'));
const Speech = React.lazy(() => import('./pages/dashboard/pages/speech/index.tsx'));
const DashboardPricing = React.lazy(() => import('./pages/dashboard/pages/dashboard_pricing/index.tsx'));
const ImageEditor = React.lazy(() => import('./pages/dashboard/pages/image_editor/index.tsx'));
const SuccessPage = React.lazy(() => import('./pages/dashboard/pages/payment_successful/index.tsx'));
const ProfileScreen = React.lazy(() => import('./pages/dashboard/pages/profile/index.tsx'));
const SchoolAdmin = React.lazy(() => import('./pages/dashboard/pages/school_admin/dashboard/index.tsx'));
const CorporateAdmin = React.lazy(() => import('./pages/dashboard/pages/corporate_admin/dashboard/index.tsx'));
const CorporateAdminStaff = React.lazy(() => import('./pages/dashboard/pages/corporate_admin/staffs/index.tsx'));
const CorporateAdminTicket = React.lazy(() => import('./pages/dashboard/pages/corporate_admin/ticket/index.tsx'));
const SchoolAdminTicket = React.lazy(() => import('./pages/dashboard/pages/school_admin/ticket/index.tsx'));
const Teachers = React.lazy(() => import('./pages/dashboard/pages/school_admin/teachers/index.tsx'));
const AddStaff = React.lazy(() => import('./pages/dashboard/pages/corporate_admin/add_staff/index.tsx'));
const AddTeachers = React.lazy(() => import('./pages/dashboard/pages/school_admin/add_teacher/index.tsx'));
const SchoolProfileScreen = React.lazy(() => import('./pages/dashboard/pages/school_admin/profile/index.tsx'));
const DashboardFrame = React.lazy(() => import('./components/DashboardFrame.tsx'));
const DataVisualizer = React.lazy(() => import('./pages/dashboard/pages/data_visualizer/index.tsx'));



export const chatDashboardRoutes = (): React.ReactElement =>  {
    return (
        <Route path={'/'} 
            element={<Suspense fallback={<div><Loading /></div> }><DashboardFrame /> </Suspense>}
            errorElement={<Suspense fallback={<div><Loading /></div>}><NotFoundPage /></Suspense>}
        >
            <Route path={chatRoutePages.chat} 
                element={<Suspense fallback={<div><Loading /></div> }><Chat /> </Suspense>}
            />
            <Route path={chatRoutePages.projectReport} 
                element={<Suspense fallback={<div><Loading /></div> }><ProjectTemplateForm /> </Suspense>}
            />
             <Route path={chatRoutePages.dataAnalysis} 
                element={<Suspense fallback={<div><Loading /></div> }><CreateChart /> </Suspense>}
            />
           
            <Route path={chatRoutePages.smartChat} 
                element={<Suspense fallback={<div><Loading /></div> }><SmartSearchPage /> </Suspense>}
            />
             <Route path={chatRoutePages.customerPersonas} 
                element={<Suspense fallback={<div><Loading /></div> }><CustomerBuilder /> </Suspense>}
            />
            <Route path={chatRoutePages.writerGenie} 
                element={<Suspense fallback={<div><Loading /></div> }><Generate /> </Suspense>}
            />
            <Route path={chatRoutePages.assessment} 
                element={<Suspense fallback={<div><Loading /></div> }><Assessment /> </Suspense>}
            />
            <Route path={chatRoutePages.article} 
                element={<Suspense fallback={<div><Loading /></div> }><ArticleWriter /> </Suspense>}
            />
            <Route path={chatRoutePages.articleEditor} 
                element={<Suspense fallback={<div><Loading /></div> }><EditorPage /> </Suspense>}
            />
            <Route path={chatRoutePages.curriculum} 
                element={<Suspense fallback={<div><Loading /></div> }><Curriculum /> </Suspense>}
            />
            <Route path={chatRoutePages.keywordResearcher} 
                element={<Suspense fallback={<div><Loading /></div> }><KeywordResearcher /> </Suspense>}
            />

            <Route path={chatRoutePages.resumeEnhancer} 
                element={<Suspense fallback={<div><Loading /></div> }><ResumeEnhancer /> </Suspense>}
            />
            <Route path={chatRoutePages.coverLetterWriter} 
                element={<Suspense fallback={<div><Loading /></div> }><CoverLetterWritter /> </Suspense>}
            />
            <Route path={chatRoutePages.interviewPrep} 
                element={<Suspense fallback={<div><Loading /></div> }><InterviewPrep /> </Suspense>}
            />
            <Route path={chatRoutePages.speechSynthesizer} 
                element={<Suspense fallback={<div><Loading /></div> }><Speech /> </Suspense>}
            />
            <Route path={chatRoutePages.videoMode} 
                element={<Suspense fallback={<div><Loading /></div> }><ComingSoonPage /> </Suspense>}
            />
            <Route path={chatRoutePages.imageMaker} 
                element={<Suspense fallback={<div><Loading /></div> }><Image /> </Suspense>}
            />
            <Route path={chatRoutePages.imageMakerEdit} 
                element={<Suspense fallback={<div><Loading /></div> }><ImageEditor /> </Suspense>}
            />
            <Route path={chatRoutePages.flowChart} 
                element={<Suspense fallback={<div><Loading /></div> }><FlowChartAnalyzer /> </Suspense>}
            />
            <Route path={chatRoutePages.research} 
                element={<Suspense fallback={<div><Loading /></div> }><ResearchWriter /> </Suspense>}
            />
            <Route path={chatRoutePages.educatorResources} 
                element={<Suspense fallback={<div><Loading /></div> }><SchoolLetters /> </Suspense>}
            />
            <Route path={chatRoutePages.productDescription} 
                element={<Suspense fallback={<div><Loading /></div> }><ProductDescriptionGenerator /> </Suspense>}
            />
            <Route path={chatRoutePages.mediaResources} 
                element={<Suspense fallback={<div><Loading /></div> }><MediaResources /> </Suspense>}
            />
            <Route path={chatRoutePages.businessResources} 
                element={<Suspense fallback={<div><Loading /></div> }><BusinessResources /> </Suspense>}
            />
            <Route path={chatRoutePages.metaTag} 
                element={<Suspense fallback={<div><Loading /></div> }><MetaTagGenerator /> </Suspense>}
            />
            <Route path={chatRoutePages.schoolAdmin} 
                element={<Suspense fallback={<div><Loading /></div> }><SchoolAdmin /> </Suspense>}
            />
            <Route path={chatRoutePages.corporateAdmin} 
                element={<Suspense fallback={<div><Loading /></div> }><CorporateAdmin /> </Suspense>}
            />
            <Route path={chatRoutePages.corporateAdminStaff} 
                element={<Suspense fallback={<div><Loading /></div> }><CorporateAdminStaff /> </Suspense>}
            />
            <Route path={chatRoutePages.corporateAdminAddStaff} 
                element={<Suspense fallback={<div><Loading /></div> }><AddStaff /> </Suspense>}
            />
            <Route path={chatRoutePages.corporateAdminTicket} 
                element={<Suspense fallback={<div><Loading /></div> }><CorporateAdminTicket /> </Suspense>}
            />
            <Route path={chatRoutePages.schoolAdminTicket} 
                element={<Suspense fallback={<div><Loading /></div> }><SchoolAdminTicket /> </Suspense>}
            />
             <Route path={chatRoutePages.noteSummarizer} 
                element={<Suspense fallback={<div><Loading /></div> }><NoteSummarizer /> </Suspense>}
            />
              <Route path={chatRoutePages.activity} 
                element={<Suspense fallback={<div><Loading /></div> }><ActivityGenerator /> </Suspense>}
            />
              <Route path={chatRoutePages.lesson} 
                element={<Suspense fallback={<div><Loading /></div> }><LessonPlanner /> </Suspense>}
            />
              <Route path={chatRoutePages.syllabus} 
                element={<Suspense fallback={<div><Loading /></div> }><SyllabusScreen /> </Suspense>}
            />
            <Route path={chatRoutePages.youtubeTranscript} 
                element={<Suspense fallback={<div><Loading /></div> }><YouTubeTranscript /> </Suspense>}
            />
            <Route path={chatRoutePages.schoolProfile} 
                element={<Suspense fallback={<div><Loading /></div> }><SchoolProfileScreen /> </Suspense>}
            />
            <Route path={chatRoutePages.studentResources} 
                element={<Suspense fallback={<div><Loading /></div> }><SchoolResources /> </Suspense>}
            />
            <Route path={chatRoutePages.schoolAdminTeacher} 
                element={<Suspense fallback={<div><Loading /></div> }><Teachers /> </Suspense>}
            />
            <Route path={chatRoutePages.schoolAdminAddTeacher} 
                element={<Suspense fallback={<div><Loading /></div> }><AddTeachers /> </Suspense>}
            />
            {/* <Route path={chatRoutePages.pricing} 
                element={<Suspense fallback={<div><Loading /></div> }><DashboardPricing /> </Suspense>}
            /> */}
            <Route path={chatRoutePages.aPlusEssay} 
                element={<Suspense fallback={<div><Loading /></div> }><APlusEssay /> </Suspense>}
            />
            <Route path={chatRoutePages.quizMaker} 
                element={<Suspense fallback={<div><Loading /></div> }><QuizMaker /> </Suspense>}
            />
            <Route path={chatRoutePages.paymentSuccess} 
                element={<Suspense fallback={<div><Loading /></div> }><SuccessPage /> </Suspense>}
            />
            <Route path={chatRoutePages.profile} 
                element={<Suspense fallback={<div><Loading /></div> }><ProfileScreen /> </Suspense>}
            />
            <Route path={chatRoutePages.visualizer} 
                element={<Suspense fallback={<div><Loading /></div> }><DataVisualizer /> </Suspense>}
            />
            <Route path={chatRoutePages.assignmentHelper} 
                element={<Suspense fallback={<div><Loading /></div> }><AssignmentSolver /> </Suspense>}
            />
             <Route path={chatRoutePages.transcribe} 
                element={<Suspense fallback={<div><Loading /></div> }><Transcriber /> </Suspense>}
            />
             <Route path={chatRoutePages.translate} 
                element={<Suspense fallback={<div><Loading /></div> }><TextTranslator /> </Suspense>}
            />
        </Route>
    );
}
