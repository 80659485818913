import { Modal, Input, Card, Row, Col, Dropdown, MenuProps, Empty } from 'antd';
import { abbreviateFullName, getColorByFirstLetter } from '../function/helper';
import './InviteModal.scss';
import { useDocumentContext } from '@src/context/DocumentContext';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import { changeRole, deleteCollaborator, postInvite } from '../function/Collaborator';
import { useState } from 'react';
import collab from '@assets/essay/Collab.png';

interface InviteModalProps {
    isModalVisible: boolean;
    setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    users: DocumentData | null;
    docId: string;
}

// Define the Data interface
interface DocumentData {
    id: string;
    data: string;
    prompt: string;
    promptType: string;
    versions: string[];
    createdAt: string;
    updatedAt: string;
    collaborators: Collaborator[];
}

interface Collaborator {
    userId: string; // Assuming userId corresponds to an email in some cases
    email: string;
    acceptInvite: boolean;
    access: string;
    inviteExpiryDate: string;
}

const InviteModal = ({ isModalVisible, setIsModalVisible, users, docId }: InviteModalProps) => {
    const [searchUser, setSearchUser] = useState('');
    const [selectInputRole, setSelectInputRole] = useState<string>('');
    const { error, fetchDocument } = useDocumentContext();
    const [hoveredUserId, setHoveredUserId] = useState<string | null>(null);
    const [isRole, setIsRole] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onInvite = async () => {
        if (selectInputRole === '' || selectInputRole === 'Permission') {
            toast.error('Please select a role');
            return;
        }
        const userDetails = { email: searchUser, docId: docId, role: selectInputRole };
        // Assume userId corresponds to the email
        setLoading(true);
        try {
            const response = await postInvite(userDetails);
            if (response?.includes('Error')) {
                toast.error(response);
                return;
            }
            toast.success(response);
            fetchDocument(docId, false);
        } catch (err) {
            toast.error(error || 'Invitation failed');
        } finally {
            setLoading(false);
            setSearchUser('');
        }
    };

    const changeRoleHandler = async (role: string, email: string) => {
        const userDetails = { email: email, docId: docId, role: role }; // Assume userId corresponds to the email
        try {
            setIsRole(true);
            const response = await changeRole(userDetails);
            console.log('error', response);

            if (response?.includes('successfully')) {
                toast.success('User role has been changed successfully');
                fetchDocument(docId, false);
            } else {
                toast.error('Role change failed');
            }
        } catch (err) {
            toast.error(error?.toString() || 'Role change failed');
        } finally {
            setIsRole(false);
        }
    };
    const getMenuItems = (userId: string, userEmail: string): MenuProps['items'] => [
        {
            key: '1',
            label: 'Editor',
            onClick: () => {
                changeRoleHandler('edit', userEmail);
            },
        },
        {
            key: '2',
            label: 'View only',
            onClick: () => {
                changeRoleHandler('view', userEmail);
            },
        },
        {
            key: '3',
            label: 'Remove',
            onClick: async () => {
                setIsRole(true);
                const response = await deleteCollaborator(userEmail, docId);
                if (response === 'User removed Sucessfully') {
                    toast.success(response);
                    fetchDocument(docId, false);
                } else {
                    toast.error(response);
                    setIsRole(false);
                }
                setIsRole(false);
            },
        },
    ];

    const getInputMenuItems = (): MenuProps['items'] => [
        {
            key: '1',
            label: 'Editor',
            onClick: () => {
                setSelectInputRole('edit');
            },
        },
        {
            key: '2',
            label: 'View only',
            onClick: () => {
                setSelectInputRole('view');
            },
        },
    ];

    return (
        <div>
            <Modal
                className="invite_modal"
                title={
                    <div className="font-poppins flex flex-row items-center justify-between text-xl font-medium leading-7 text-left text-black">
                        <div className='text-lg font-semibold leading-6 text-left'>User Management</div>
                    </div>
                }
                style={{ height: '400px' }}
                open={isModalVisible}
                footer={null}
                onCancel={handleCancel}
            >
                <div className="border-b border-gray-200 mt-6 mb-6"></div>
                <Input
                    className="h-[3rem]"
                    placeholder="Search for email"
                    value={searchUser}
                    onChange={(e) => setSearchUser(e.target.value)}
                    suffix={
                        <Dropdown menu={{ items: getInputMenuItems() }} trigger={['click']}>
                            <button
                                onClick={(e) => e.preventDefault()}
                                className="text-blue-gray flex items-center flex-row gap-3 text-sm font-normal leading-5 text-left"
                            >
                                {!selectInputRole && (
                                    <span className="text-blue-gray text-sm font-normal leading-5">Permission</span>
                                )}
                                {selectInputRole !== '' && (
                                    <span>{selectInputRole === 'edit' ? 'Editor' : 'View only'} (All) </span>
                                )}
                                <span>
                                    <svg
                                        width="11"
                                        height="6"
                                        viewBox="0 0 11 6"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M5.5 6L10.2631 0H0.73686L5.5 6Z" fill="#E55109" />
                                    </svg>
                                </span>
                            </button>
                        </Dropdown>
                    }
                />

                <div>
                    {users?.collaborators !== undefined && users?.collaborators.length > 0 ? (
                        <div className="h-[240px] hover:overflow-y-auto overflow-hidden mt-6 relative">
                            {isRole && (
                                <div className="absolute inset-0 flex items-center justify-center">
                                    <CircularProgress />
                                </div>
                            )}
                            <div className="relative z-10">
                                {' '}
                                {users?.collaborators.map((item) => (
                                    <Card
                                        className="invite_modal_card"
                                        key={item.userId}
                                        style={{
                                            marginBottom: 10,
                                            backgroundColor: hoveredUserId === item.userId ? '#f0f0f0' : '#fff',
                                            cursor: 'pointer',
                                        }}
                                        onMouseEnter={() => setHoveredUserId(item.userId)}
                                        onMouseLeave={() => setHoveredUserId(null)}
                                    >
                                        <Row align="middle">
                                            <Col flex="33px">
                                                <div
                                                    className="w-8 h-8 gap-0 rounded-full flex items-center justify-center"
                                                    style={{
                                                        backgroundColor: getColorByFirstLetter(item.email || ''),
                                                    }}
                                                >
                                                    {abbreviateFullName(item.email || '')}
                                                </div>
                                            </Col>
                                            <Col
                                                flex="auto"
                                                style={{ paddingLeft: '10px' }}
                                                className="text-sm font-normal leading-5 text-left text-black font-poppins"
                                            >
                                                {item.email || ''}
                                            </Col>
                                            <Col onClick={(e) => e.stopPropagation()}>
                                                <Dropdown
                                                    menu={{ items: getMenuItems(item.userId, item.email) }}
                                                    trigger={['click']}
                                                >
                                                    <button
                                                        onClick={(e) => e.preventDefault()}
                                                        className="text-blue-gray flex items-center flex-row gap-3 text-sm font-normal leading-5 text-left"
                                                    >
                                                        {item.access === 'edit' ? 'Editor' : 'View only'} (All)
                                                        <span>
                                                            <svg
                                                                width="11"
                                                                height="6"
                                                                viewBox="0 0 11 6"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M5.5 6L10.2631 0H0.73686L5.5 6Z"
                                                                    fill="#E55109"
                                                                />
                                                            </svg>
                                                        </span>
                                                    </button>
                                                </Dropdown>
                                            </Col>
                                        </Row>
                                    </Card>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <div className="text-center pt-5 flex flex-col items-center justify-center  text-sm font-normal leading-5 text-left text-black font-poppins">
                            <Empty image={collab} description="No collaborators found" />
                        </div>
                    )}
                </div>
                <div className='border-b border-gray-200 mt-4 mb-4'></div>

                <div className=" flex justify-end mt-10">
                    <button
                        
                        onClick={onInvite}
                        className="px-5 py-3 bg-[#E55109] h-[40px] rounded-lg text-white font-semibold hover:opacity-80"
                    >
                        {loading ? <CircularProgress size={24} color="inherit" /> : 'Invite User'}
                    </button>
                </div>
            </Modal>
        </div>
    );
};
export default InviteModal;
