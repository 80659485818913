import React, { useState } from "react";
import { Dropdown, Button, Menu } from "antd";
import { DownOutlined, PlusOutlined, EditOutlined, DeleteOutlined, ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";

export const Review = ({
  contents,
  setContent,
}) => {
  const [contentLines, setContentLines] = useState(
    contents.split(/\d+\.\d* /).filter(Boolean)
  );

  const [editingIndex, setEditingIndex] = useState(null);

  const updateContents = (lines) => {
    setContentLines(lines);
    setContent(lines.map((line, i) => `${i + 1}. ${line}`).join("\n"));
  };

  const moveItem = (fromIndex, toIndex) => {
    if (toIndex < 0 || toIndex >= contentLines.length) return;
    const updatedLines = [...contentLines];
    const [movedItem] = updatedLines.splice(fromIndex, 1);
    updatedLines.splice(toIndex, 0, movedItem);
    updateContents(updatedLines);
  };

  const addItem = () => {
    updateContents([...contentLines, "New Item"]);
  };

  const editItem = (index, newValue) => {
    const updatedLines = [...contentLines];
    updatedLines[index] = newValue;
    updateContents(updatedLines);
  };

  const handleDelete = (index) => {
    updateContents(contentLines.filter((_, i) => i !== index));
  };

  return (
    <div>
      <h2 className="text-xl font-bold mb-4 dark:text-gray-300">
        Review & Edit Your Table of Contents
      </h2>
      <div className="mt-4 flex flex-col space-y-2">
        {contentLines.map((line, index) => (
          <div
            key={index}
            className="bg-white rounded shadow p-2 w-full md:w-[70%] flex items-center space-x-4"
          >
            <span>{index + 1}.</span>
            {editingIndex === index ? (
              <input
                type="text"
                value={line}
                onChange={(e) => editItem(index, e.target.value)}
                onBlur={() => setEditingIndex(null)}
                className="flex-1 p-1 border rounded focus:outline-none focus:ring focus:ring-blue-300"
                autoFocus
              />
            ) : (
              <span className="flex-1">{line}</span>
            )}
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item key="edit" onClick={() => setEditingIndex(index)}>
                    <EditOutlined /> Edit
                  </Menu.Item>
                  <Menu.Item key="delete" onClick={() => handleDelete(index)} disabled={index === 0}>
                    <DeleteOutlined /> Delete
                  </Menu.Item>
                  <Menu.Item key="move-up" onClick={() => moveItem(index, index - 1)} disabled={index === 0}>
                    <ArrowUpOutlined /> Move Up
                  </Menu.Item>
                  <Menu.Item key="move-down" onClick={() => moveItem(index, index + 1)} disabled={index === contentLines.length - 1}>
                    <ArrowDownOutlined /> Move Down
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <Button>
                Actions <DownOutlined />
              </Button>
            </Dropdown>
          </div>
        ))}
      </div>
      <Button
        onClick={addItem}
        type="primary"
        icon={<PlusOutlined />}
        className="mt-4  bg-customOrangee text-white hover:bg-customOrangee hover:text-white"
      >
        Add Item
      </Button>
    </div>
  );
};
