/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useRef, useState, useEffect } from 'react';
import { io, Socket } from 'socket.io-client';
import { BASE_URL } from '@src/utils/constants';
import { useDocumentContext } from '@src/context/DocumentContext';
import { useUserContext } from '@src/context/UseProvider';
import { Chats, ChatType } from '@src/pages/dashboard/endpoints';
import { useLocation, useOutletContext } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import Markdown from '@src/components/Markdown';
import { useDebouncer } from '@src/pages/dashboard/function/helper';
import Profile from '@assets/Avatar.svg';
import NewEditorPage from '@src/pages/dashboard/pages/essay/editor/newInstanceOfEditor';
import NewSunEditorComponent from '@src/pages/dashboard/pages/essay/sun_editor copy';
import axios from 'axios';
import Loading from '@src/components/Loading';
import { set } from 'lodash';
import { ArrowDownOutlined, ArrowUpOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';

interface Section {
    title: string;
    content: string;
    reference?: string[];
}

interface UserDocOn {
    documentId: string;
    users: string[];
    admin: any;
    collaborators: any[];
}

const FinishPage = ({
    slide,
    setSlide,
    isStreamEnd,
    generate,
    setGenerate,
    setHtmlContent,
    essayRef,
    setStreaming,
    docId,
    setDocId,
    isSummarize,
    response,
    setResponse,
    selectedResearchStyle,
    topic,
    grade,
    no,
    details,
    selectedTone,
}: {
    slide: Section[];
    setSlide: React.Dispatch<React.SetStateAction<Section[]>>;
    isStreamEnd?: boolean;
    generate?: boolean;
    setGenerate?: React.Dispatch<React.SetStateAction<boolean>>;
    setHtmlContent?: any;
    essayRef?: any;
    streaming?: boolean;
    setStreaming?: React.Dispatch<React.SetStateAction<string | undefined>>;
    docId: string | undefined;
    setDocId: React.Dispatch<React.SetStateAction<string | undefined>>;
    isSummarize?: boolean;
    response: string;
    setResponse: React.Dispatch<React.SetStateAction<string>>;
    selectedResearchStyle: string;
    topic: string;
    grade: string;
    no: number;
    details: any;
    selectedTone: string;
}) => {
    const [sockets, setSockets] = useState<{ [key: string]: Socket | null }>({});
    const [content, setContent] = useState<string>('');
    const [socket, setSocket] = useState<Socket | null>(null);

    const [socketKey, setSocketKey] = useState<string[]>(['1', '2', '3', '4', '5', '6']);
    const [references, setReferences] = useState<Section[]>([]);
    const editorInstanceRef = useRef<any>();

    const [editorContent, setEditorContent] = useState<any>('');
    const [humanizedContent, setHumanizedContent] = useState<any>();
    const [translatedContent, setTranslatedContent] = useState<any>();
    const [editorData, setEditorData] = useState('');
    const { documentResponse } = useDocumentContext();
    const { userDetails } = useUserContext();
    const [userOnDoc, setUserOnDoc] = useState<UserDocOn>();
    const [highlightedTexts, setHighlightedTexts] = useState<string>('');
    const [latestRespone, setLatestRespone] = useState<string>('');
    const [isTyping, setIsTyping] = useState(false);
    const [editingIndex, setEditingIndex] = useState<number | null>(null);
    const [editedTitle, setEditedTitle] = useState<string>('');
    const [editedContent, setEditedContent] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const {
        setShowingHistory,
        showingHistory,

        promptSent,
    } = useOutletContext<any>();

    const sendChanges = useDebouncer((documentId: string, userId: string, data: string) => {
        console.log('sendChanges', documentId, userId, data);
        socket?.emit('send-changes', {
            documentId,
            userId,
            data,
        });
    }, 1000);

    const extractReferences = (content: Section) => {
        if (!content?.content) {
            console.warn('Content is undefined or does not have a "content" property', content);
            return content; // Return the unmodified content to avoid breaking the loop
        }

        // Updated pattern to capture everything after "References:" until the end of the content
        const referenceSectionPattern = /References:(.*)$/s;
        const referencesListPattern = /References List:(.*)$/s;
        const referenceSectionMatch =
            content.content.match(referenceSectionPattern) || content.content.match(referencesListPattern);

        let fullReferences: string[] = [];
        let cleanedContent = content.content;

        if (referenceSectionMatch) {
            // Extract only the text after "References:" or "References List:"
            const referenceText = referenceSectionMatch[1].trim();

            // Split by year in parentheses, keeping the year as part of the reference
            fullReferences = referenceText
                .split(/(?=\(\d{4}\))/) // Lookahead for a year like (2010), (1996), etc.
                .map((ref) => ref.trim())
                .filter(Boolean);

            // Remove the reference section from the main content
            cleanedContent = cleanedContent.replace(referenceSectionMatch[0], '').trim();
            console.log('Full References:', fullReferences);
        }

        return {
            ...content,
            content: cleanedContent,
            reference: fullReferences,
        };
    };

    useEffect(() => {
        setStreaming?.(true);
    }, []);

    useEffect(() => {
        if (isStreamEnd) {
            if (!isEditing) {
                toast.info('Generating executive summary...');
            }
            const updatedSections = slide.map((section) => extractReferences(section));

            const essayContent = document.getElementById('essay')?.innerHTML;

            setHtmlContent(essayContent);
        }
    }, [isStreamEnd]);

    const onGenerateContent = async (index: number) => {
        setLoading(true);

        const currentSlide = slide[index];
        if (!currentSlide) {
            console.error('Error: Section not found at index', index);
            return;
        }

        const contentNumber = slide.length;
        let no = length / contentNumber;
        const introductionReduction = no * 0.4;
        const redistributedWords = introductionReduction;

        if (index === 0) {
            no = no * 0.6;
        } else if (index === 1) {
            no += redistributedWords;
        }

        console.log('Word Count for Generation:', no);
        const newPrompt = `
        Generate detailed and informative content for the following research subtitle: "${currentSlide.title}" based on the topic "${topic}" using the tone "${selectedTone}, Using this ${grade} level of language, and the research type "${selectedResearchStyle}".
        Ensure the research type aligns with "${selectedResearchStyle}."
        
        **Content Requirements:**.
        - ** Note: Content should only be taken from 1 source and avoid citing multiple references
        - Must contain at least ${Math.floor(no)} words.
        - No title or headings in the content.
        - Add references as in-text citations within the content.
        - Use HTML tags except <i>. Avoid unnecessary formatting.
        - Include subheadings for each section. it should be numbering the subheadings.  <h2> subheadings should be numbered.</h2>
        - The number is ${index + 1} ,
        - if ${index === 0} then the number is ${index + 1} so subheadings should be ${index + 1}.1 
        - if ${index === 1} then the number is ${index + 1} so subheadings should be ${index + 1}.1, and so on.
        - generate content for heading ${index + 1} but it must not have a title just the content.
        - Do not give context to the content. Just give the content.
        
        
        
        **Reference Formatting:**
        - References must be presented in a bullet list using <ul> and <li> tags.
        - Format should follow standard citation styles (APA, MLA, Chicago, etc.).
        - Each reference should include:
          - Author(s)
          - Year of publication
          - Title (italicized for books, reports, or journals)
          - Journal or book details
          - URL or DOI for online sources.
        
        **Example:**
        References:
        <ul>
          <li>Smith, J. (2021). <em>The Effects of Climate Change on Global Agriculture</em>. Journal of Environmental Science, 34(5), 112-125. doi:10.1234/jes.2021.0045</li>
          <li>Brown, A., & Taylor, P. (2019). <em>Advances in Artificial Intelligence</em>. Cambridge University Press.</li>
          <li>World Health Organization. (2020). <em>Global Health Statistics 2020</em>. Retrieved from https://www.who.int/publications/global-health-statistics-2020</li>
        </ul>
        
        ** Note: Content should only be taken from 1 source and avoid citing multiple references
        
        **Notes:**
        - Do not include additional text or explanations.
        - Use 'References:' as the header for the citations list.
        
        `;

        const token = localStorage.getItem('token');
        try {
            const response = await axios.post(
                `${BASE_URL}/knowledge-base`,
                {
                    model: 'gpt-4o',
                    messages: [
                        {
                            role: 'system',
                            content: newPrompt,
                        },

                        {
                            role: 'user',
                            content: `Generate content for this title ${currentSlide.title} `,
                        },
                    ],
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            );

            const AIRESULT = response?.data?.data?.choices[0]?.message;
            setEditedContent(AIRESULT?.content);
        } catch (error) {
            console.error('Error generating content:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isEditing) {
            toast.success('Content edited successfully');
            setIsEditing(false);
        }
    }, [slide]);

    useEffect(() => {
        if (isStreamEnd && isSummarize) {
            setReferences([]);
            if (!isEditing) {
                toast.success('Executive summary generated successfully');
            }
            const updatedSections = slide.map((section) => extractReferences(section));
            setReferences(updatedSections);
            console.log(updatedSections);

            const essayContent = document.getElementById('essay')?.innerHTML;

            setHtmlContent(essayContent);
        }
    }, [isSummarize, isStreamEnd]);

    useEffect(() => {
        if (references.length > 0) {
            const essayContent = references
                .map((section) => `<h1>${section.title}</h1><p>${section.content}</p>`)
                .join('');

            // Collect all references
            const allReferences = references
                .flatMap((section) => section.reference || []) // Flatten and filter out undefined references
                .map((ref, index) => `<p> ${ref}</p>`)
                .join('');

            // Combine essay content and references
            const finalEssayContent = essayContent + (allReferences ? `<h2>References</h2>${allReferences}` : '');
            setHtmlContent(finalEssayContent);
            console.log('Essay Content:', finalEssayContent);
            setResponse(finalEssayContent);
        }
    }, [references]);

    useEffect(() => {
        setStreaming?.(false);
    }, [isStreamEnd]);

    const handleEdit = (index: number, section: Section) => {
        setEditingIndex(index);
        setEditedTitle(section.title);
        setEditedContent(section.content);
    };

    const handleSave = (index: number) => {
        setIsEditing(true);
        const updatedSlide = [...slide];
        updatedSlide[index] = { ...updatedSlide[index], title: editedTitle, content: editedContent };
        setSlide(updatedSlide);
        setEditingIndex(null);
    };

    // Move section up
    const moveSectionUp = (index: number) => {
        if (index === 0) return; // First item can't move up
        const updatedSlide = [...slide];
        [updatedSlide[index], updatedSlide[index - 1]] = [updatedSlide[index - 1], updatedSlide[index]];
        setSlide(updatedSlide);
    };

    // Move section down
    const moveSectionDown = (index: number) => {
        if (index === slide.length - 1) return; // Last item can't move down
        const updatedSlide = [...slide];
        [updatedSlide[index], updatedSlide[index + 1]] = [updatedSlide[index + 1], updatedSlide[index]];
        setSlide(updatedSlide);
    };

    // Delete section
    const deleteSection = (index: number) => {
        const updatedSlide = slide.filter((_, i) => i !== index);
        setSlide(updatedSlide);
    };

    const addSection = (index: number) => {
        const newSection: Section = {
            title: 'New Section',
            content: 'Enter content here...',
            reference: [],
        };
    
        // Insert new section at the correct position
        const updatedSlide = [...slide];
        updatedSlide.splice(index + 1, 0, newSection);
        setSlide(updatedSlide);
    
        // Wait for UI to update, then scroll to the new section
        setTimeout(() => {
            document.getElementById(`section-${index + 1}`)?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 100);
    };
    
    

    return (
        <div id="essay" ref={essayRef}>
            {!generate ? (
                <>
                    <div>
                        {slide.map((s, index) => (
                            <div key={index} className='hover:shadow-lg  bg-transparent transition-shadow hover:dark:shadow-gray-700 border border-gray-300 dark:border-gray-700 rounded-md p-4 mb-2'>
                                {editingIndex === index ? (
                                    <>
                                        <input
                                            type="text"
                                            value={editedTitle}
                                            onChange={(e) => setEditedTitle(e.target.value)}
                                            className="border p-2 w-full focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 dark:text-white "
                                        />
                                        <div className="relative">
                                            {loading && (
                                                <div className="absolute top-0 left-0 w-full flex items-center justify-center bg-white bg-opacity-80 py-2 z-10">
                                                    <div className="w-5 h-5 border-4 border-gray-300 border-t-customOrange rounded-full animate-spin"></div>
                                                    <span className="ml-2 text-gray-600 text-sm">
                                                        Generating content...
                                                    </span>
                                                </div>
                                            )}
                                            <textarea
                                                value={editedContent}
                                                onChange={(e) => setEditedContent(e.target.value)}
                                                className=" border p-2 w-full  focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 dark:text-white "
                                                rows={20}
                                            />
                                        </div>

                                        <div className="flex flex-row items-end justify-between mb-3">
                                            <button
                                                onClick={() => setEditingIndex(null)}
                                                className="bg-gray-500 text-white p-2 rounded-md"
                                            >
                                                Cancel
                                            </button>

                                            <div className="flex flex-row items-center gap-3 ">
                                                <button
                                                    onClick={() => onGenerateContent(index)}
                                                    className="bg-customOrange rounded-md text-white p-2"
                                                >
                                                    Regenerate
                                                </button>

                                                <button
                                                    onClick={() => handleSave(index)}
                                                    className="bg-green-500 text-white p-2 rounded-md"
                                                >
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="flex flex-row items-start justify-between" id={`section-${index} `}>
                                            <div className="flex flex-col gap-3">
                                                <h1 className="text-[1.4rem] font-bold dark:text-white">{s.title}</h1>
                                                <Markdown>{s.content}</Markdown>
                                            </div>
                                            <div className="flex flex-col  items-center gap-2 px-2">
                                                <Button
                                                    onClick={() => handleEdit(index, s)}
                                                    className="text-gray-500 hover:text-blue-500"
                                                >
                                                    <EditOutlined className="text-lg" />
                                                </Button>
                                                <Button
                                                    onClick={() => moveSectionUp(index)}
                                                    className="text-gray-500 hover:text-green-500"
                                                >
                                                    <ArrowUpOutlined className="text-lg" />
                                                </Button>
                                                <Button
                                                    onClick={() => moveSectionDown(index)}
                                                    className="text-gray-500 hover:text-green-500"
                                                >
                                                    <ArrowDownOutlined className="text-lg" />
                                                </Button>
                                                <Button
                                                    onClick={() => deleteSection(index)}
                                                    className="text-red-500 hover:text-red-700"
                                                >
                                                    <DeleteOutlined className="text-lg" />
                                                </Button>

                                                <Button
                                                    onClick={()=>addSection(index)}
                                                    
                                                >
                                                    <PlusOutlined className="text-lg" />
                                                    
                                                </Button>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        ))}
                        <p className="text-lg font-bold text-gray-800">References</p>

                        {slide.map((s, index) => (
                            <p key={index} className="text-sm text-gray-600 mt-2">
                                {s.reference}
                            </p>
                        ))}
                    </div>
                    <div style={{ display: 'none' }} id="extracted-content">
                        {references.map((s, index) => (
                            <div key={index}>
                                <h1 className="text-lg font-bold text-gray-800 dark:text-white mt-2">
                                    {' '}
                                    {`${s.title}`}
                                </h1>
                                <Markdown>{s.content}</Markdown>
                            </div>
                        ))}

                        <h1 className="text-lg font-bold text-gray-800">References:</h1>
                        {references.map((s, index) => (
                            <Markdown key={index}>{String(s.reference)}</Markdown>
                        ))}
                    </div>
                </>
            ) : (
                <div className="h-screen mb-20">
                    <NewEditorPage
                        docId={docId}
                        setDocId={setDocId}
                        editorInstanceRef={editorInstanceRef}
                        regenerate={() => {}}
                        latestRespone={latestRespone}
                        setLatestRespone={setLatestRespone}
                        highlightedTexts={highlightedTexts}
                        setHighlightedTexts={setHighlightedTexts}
                        content={
                            <NewSunEditorComponent
                                latestRespone={latestRespone}
                                setLatestRespone={setLatestRespone}
                                highlightedTexts={highlightedTexts}
                                setHighlightedTexts={setHighlightedTexts}
                                editorInstanceRef={editorInstanceRef}
                                hasAccess={!isTyping && (documentResponse?.role ?? '') !== 'view' ? true : false}
                                initial_content={response}
                                onChangeEditorContent={(e: string) => {
                                    setEditorContent(e);
                                    const newtext = e;
                                    console.log('newtext', newtext);

                                    console.log('docId', docId);
                                    console.log('userDetails?.userId', userDetails?.userId);

                                    sendChanges(docId, userDetails?.userId, e);
                                }}
                            />
                        }
                        showTopNavBar={false}
                        showInvite={true}
                        isTyping={isTyping}
                        Profile={Profile}
                        promptSent={promptSent}
                        showingHistory={showingHistory}
                        setGenerate={setGenerate}
                        setShowingHistory={setShowingHistory}
                        generate={generate}
                        setEditorContent={setEditorContent}
                        editorContent={editorContent}
                        translatedContent={translatedContent}
                        setTranslatedContent={setTranslatedContent}
                        humanizedContent={humanizedContent}
                        setHumanizedContent={setHumanizedContent}
                        setUserOnDoc={setUserOnDoc}
                        userOnDoc={userOnDoc}
                    />
                </div>
            )}
        </div>
    );
};
export default FinishPage;
