import { newGenerationHomeDataProps, TranslatorLangProps } from '../interface';
import syllabusImg from '@icons/syllabus.svg';
import lessonPlanner from '@icons/lesson_planner.svg';
import ShareImg from '@public/share.svg';
import Copy from '@public/copy.svg';
import DownloadImage from '@public/download.svg';
import research from '@icons/research.svg';
import article from '@icons/article.svg';
import comprehensive from '@icons/comphrensive.svg';
import socialMedia from '@icons/social_media.svg';
import letterImg from '@icons/letter.svg';
import { copyData, handleShare } from '@src/utils/app_functions';
import { PromptOptionsType } from '@src/core/interfaces/general';
import transcribe from '@assets/essay/transcription.png';
import translate from '@icons/translation.png';
import { ReportCardSvg } from '../svg';
import TextTranslator from '../sub_pages/translator';
import summary from '@icons/summary.png';
import webAnalytics from '@icons/web-analytics.png';
import { chatRoutePages } from '@src/utils/app_routes';

const newGenerationHomeData: Array<newGenerationHomeDataProps> = [
    {
        img: webAnalytics,
        head: 'Data Insights Tool',
        text: 'Collect data summaries and generate insightful charts for your project.',
        pageIndex: 10,
        pageName: 'Data Insight Tool',
        label: 'data_visualizer_tool',
    },
    // {
    //     img: syllabusImg,
    //     head: 'Copywriting Tool',
    //     text: 'Create a comprehensive copywriting plan for your project.',
    //     pageIndex: 1,
    //     pageName: "Copywriting Tool",
    //     label: 'copywriting_tool',
    // },
    // {
    //     img: comprehensive,
    //     head: 'Campaign Ad Generator',
    //     text: 'Generate ads that resonate with your audience and drive results.',
    //     pageIndex: 2,
    //     pageName: "Campaign generator",
    //     label: 'campaign_generator',
    // },
    // {
    //     img: letterImg,
    //     head: 'Policy Writer',
    //     text: 'Craft compelling documents that persuade and inspire action.',
    //     pageIndex: 3,
    //     pageName: "Policy Writer",
    //     label: 'policy_writer',
    // },
    // {
    //     img: lessonPlanner,
    //     head: 'Job Description Writer',
    //     text: 'Craft compelling job descriptions that attract top talent.',
    //     pageIndex: 4,
    //     pageName: "Job Description",
    //     label: 'job_description',
    //     imgElement: ReportCardSvg()
    // },
    {
        img: research,
        head: 'Project Report Generator',
        text: 'Produce detailed project reports that comprehensively summarize project objectives.',
        pageIndex: 5,
        pageName: 'Project Report',
        label: 'project_report',
    },
    {
        img: transcribe,
        head: 'Transcriber',
        text: 'Accurately transcribe audio content, ensuring high-quality text output.',
        pageIndex: 6,
        pageName: 'Transcriber',
        label: 'transcriber',
    },
    // {
    //     img: socialMedia,
    //     head: 'Social Media Writer',
    //     text: 'Turn your ideas into shareable, likable, and lovable social media content, fast',
    //     pageIndex: 7,
    //     pageName: "Social Media",
    //     label: 'social_media',
    // },
    {
        img: translate,
        head: 'Text Translator',
        text: 'Quickly transform your thoughts into engaging, shareable, and impactful social media content that resonates with your audience.',
        pageIndex: 8,
        pageName: 'Text Translator',
        label: 'text_translator',
    },

    {
        img: letterImg,
        head: 'Product Description Generator',
        text: "Generate product descriptions that effectively communicate your product's features and benefits.",
        pageIndex: 10,
        pageName: 'Product Description',
        label: chatRoutePages.productDescription,
        naviagatePage: true,
    },
    {
        img: research,
        head: 'Flow Chart Analyzer',
        text: 'Analyze your flow charts and diagrams to gain insights and make informed decisions.',
        pageIndex: 12,
        pageName: 'Flow Chart',
        label: chatRoutePages.flowChart,
        naviagatePage: true,
    },
    // {
    //     img: article,

    //     head: 'Customer Personas Builder',
    //     text: 'Generate customer personas for your business.',
    //     pageIndex: 10,
    //     pageName: 'Customer Personas Builder',
    //     naviagatePage: true,
    //     label: chatRoutePages.customerPersonas,
    // },
];

const contentPromptOptionsData = ({
    response,
    onSuccess,
    urlRef,
    showDownload,
}: {
    response: string;
    onSuccess: React.Dispatch<React.SetStateAction<boolean>>;
    urlRef: React.MutableRefObject<string>;
    showDownload: React.Dispatch<React.SetStateAction<boolean>>;
}): PromptOptionsType[] => [
    {
        name: 'Share',
        icon: ShareImg,
        onClick: () =>
            handleShare({
                response,
                onSuccess,
                urlRef,
            }),
    },
    {
        name: 'Copy',
        icon: Copy,
        onClick: () => copyData(response),
    },
    {
        name: 'Download',
        icon: DownloadImage,
        onClick: () => showDownload(true),
    },
];

const contentPromptOptionsData2 = ({
    response,
    showDownload,
}: {
    response: string;
    showDownload: React.Dispatch<React.SetStateAction<boolean>>;
}): PromptOptionsType[] => [
    {
        name: 'Copy',
        icon: Copy,
        onClick: () => copyData(response),
    },
    {
        name: 'Download',
        icon: DownloadImage,
        onClick: () => showDownload(true),
    },
];

const textTranslatorLangOptions: TranslatorLangProps[] = [
    {
        name: 'English',
        value: 'english',
    },
    // {
    //     name: 'Spanish',
    //     value: 'spanish',
    // },
    // {
    //     name: 'French',
    //     value: 'french',
    // },
    // {
    //     name: 'German',
    //     value: 'german',
    // },
    // {
    //     name: 'Mandarin',
    //     value: 'mandarin',
    // },
    {
        name: 'Yoruba',
        value: 'yoruba',
    },
    {
        name: 'Igbo',
        value: 'igbo',
    },
    {
        name: 'Hausa',
        value: 'hausa',
    },
    // {
    //     name: 'Arabic',
    //     value: 'arabic',
    // },
    // {
    //     name: 'Hindi',
    //     value: 'hindi',
    // },
    // {
    //     name: 'Portuguese',
    //     value: 'portuguese',
    // },
    // {
    //     name: 'Swahili',
    //     value: 'swahili',
    // },
    // {
    //     name: 'Amharic',
    //     value: 'amharic',
    // },
    // {
    //     name: 'Oromo',
    //     value: 'oromo',
    // },
    // {
    //     name: 'Shona',
    //     value: 'shona',
    // },
    // {
    //     name: 'Zulu',
    //     value: 'zulu',
    // },
    // {
    //     name: 'Wolof',
    //     value: 'wolof',
    // },
    // {
    //     name: 'Twi',
    //     value: 'twi',
    // },
];

export { newGenerationHomeData, contentPromptOptionsData, contentPromptOptionsData2, textTranslatorLangOptions };
